import styles from './markingListOne2.scss';//css는 Top에 와야..
import axios from 'axios';

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import Cookies from 'js-cookie'; 
import fnc from '../mymod/commFunction';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsShowList, setSelectedRowOld, setSelectedRow, setScrollPosition3 } from '../reduxToolkit/redux';

function MarkingListOne2() {
    const svrUrl = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate();//SqlUpdate 내부에 있어야 한다.
    const scrollPosition = useSelector((state) => state.reduxStore.scrollPosition3); 
    const [list, setList] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isMarked, setIsMarked] = useState(false); // 채점 여부를 저장할 상태
    const [testCode, setTestCode] = useState(null);
    const [testCodeMun, setTestCodeMun] = useState(null);
    const [testNm, setTestNm] = useState(null);
    const [munCnt, setMunCnt] = useState(null);
    const [dapBar, setDapBar] = useState(null);
    const [markingBar, setMarkingBar] = useState(null);
    const [oxBar, setOxBar] = useState(null);
    const [munCntDone, setMunCntDone] = useState(null);
    const listRef = useRef(null);


    const location = useLocation();
    const dispatch = useDispatch();    

    
    useEffect(() => {//formLoad
      fncSelectList(); // 라우팅 시 selectClick 함수를 실행합니다. 로딩 후 실행


    }, []);
  
    // list가 변경될 때마다 실행
    useEffect(() => {
      const lastSelectedItem = Cookies.get('lastSelectedItem');
      if (list && lastSelectedItem) {
        if (listRef.current) {//스크롤 유지
          listRef.current.scrollTop = scrollPosition;
        }
      }
    }, [list]);

  
    function fncSelectList() {   
      let stCode = fnc.getCookie('stCode');
      sendAxios('/markinglistOne', { stCode }, (response) => {
        // console.log(response);
        if (response) {
          setList(response);
        } else {
          console.log("속성이 obj 내에 없습니다.");
        }
      });
    }
    
    function handleItemClick(examData) {
      setTestCode(examData.시험지코드);
      setTestCodeMun(examData.시험지코드mun);
      setTestNm(examData.시험지명);
      setMunCnt(examData.문항수);
      setSelectedItem(examData.시험지코드);//배경색 변경
      setDapBar(examData.정답);
      setOxBar(examData.OX);
      setMarkingBar(examData.마킹);
      setMunCntDone(examData.푼문항수);


      if (examData.채점=== 'Y') {
        setIsMarked(true);
      } else {
        setIsMarked(false);
      }

      dispatch(setScrollPosition3(listRef.current.scrollTop));//스크롤 유지
    }
  
    function btnMarkingClicked() {
        if (testCode === null) {
          alert('대상을 선택하세요!');
          return false;
        }

       
        const currentPath = location.pathname;
        //------------------------------초기화
        dispatch(setIsShowList(false));
        dispatch(setSelectedRowOld(0));
        dispatch(setSelectedRow(1));
      
        switch (currentPath) {
            case '/markinglistone':
              navigate('/markingoneapp', { state: { testCodeMun, testCode, testNm, munCnt }});
            break;
            case '/markinglistone2':          
              navigate('/markingoneapp2', { state: { testCodeMun, testCode, testNm, munCnt }});
            break;
            default:
        }
       
    }
  
    
  function btnMarkingStudyClicked() {
    if (testCode === null) {
      alert('대상을 선택하세요!');
      return false;
    }
    navigate('/markingstudyapp2', { state: { testCodeMun, testCode, testNm, munCnt, dapBar, markingBar, oxBar}});
}


    function btnMarkingFastClicked() {
      if (testCode === null) {
        alert('대상을 선택하세요!');
        return false;
      }
      navigate('/markingfastapp', { state: { testCodeMun, testCode, testNm, munCnt }});
  }

    const sendAxios = async (url, data, callback) => {
        try {
          const response = await axios.post(`${svrUrl}${url}`, data);
          let jsonObj = response.data;
          callback(jsonObj);
        } catch (error) {
          console.error("Axios 요청 중 오류가 발생했습니다:", error);
        }
      };
    
  
    return (
      <div className='MarkingListOne2'>
        <div className="header-container">
            <Header onBackClick={() => navigate("/main")} title="시험목록" />
        </div>  

        <div className='wrap-list-markingList' ref={listRef}>
            {
                list && list.map((examData) => (  //JSX 문법 리스트를 출력할 때 중괄호를 사용해야       
                    
             
                <div             
                    className={`row-list ${selectedItem === examData.시험지코드 ? 'selected' : ''}`} 
                    key={examData.시험지코드} 
                    onClick={() => handleItemClick(examData)}
                    style={selectedItem === examData.시험지코드 ? { backgroundColor: 'rgba(10, 100, 100,0.1)' } : {}}  
                >
                      
                        <div className="col-list">
                            <div className="lbl1-1">
                            <label className={`lbl ${examData.푼문항수 != examData.문항수 ? 'lbl-red' : ''}`}>시험명</label>
                            <label className="lbl_content" >{examData.시험지명}</label>
                            </div>
                        </div>
                        
                        <div className="col-list">
                            <div className="lbl2-1">
                            <label className={`lbl ${examData.푼문항수 != examData.문항수 ? 'lbl-red' : ''}`}>출제일</label>
                            <label>{examData.시험일자.replaceAll('-','.')}</label>
                            </div>

                            <div className="lbl2-2">
                            <label className={`lbl ${examData.푼문항수 != examData.문항수 ? 'lbl-red' : ''}`}>담임</label>
                            <label className="lbl2-2" >{examData.담임}</label>
                            </div>
                        </div>
                
                        <div className="col-list">
                            <div className="lbl2-1">
                            <label className={`lbl ${examData.푼문항수 != examData.문항수 ? 'lbl-red' : ''}`}>문항수</label>
                            <label> {examData.문항수} 문항 </label>
                            </div>
                            <div className="lbl2-2">
                            <label className={`lbl ${examData.푼문항수 != examData.문항수 ? 'lbl-red' : ''}`}>진행률</label>
                            <label className="lbl2-2"> {fnc.fncRound(examData.푼문항수, examData.문항수,0)}%</label>
                            </div>
                        </div>
            
                </div>//end-row-list
                ))
            }
        
        </div>

         <div className="footer1">
            <div className="menu">
                {<button className="menubtn" id="btn새로고침" onClick={() => fncSelectList()}>새로고침</button>}
                {false && <button className="menubtn" id="btn빠른마킹" onClick={btnMarkingFastClicked}>빠른마킹</button>}
                {!isMarked && <button className="menubtn" id="btn응시" onClick={btnMarkingClicked}>학습하기</button>}
                              
            </div>
        </div>
  
      </div>
  
 
  );
  }
  
  export default MarkingListOne2;