import React from "react";
import "./Header.scss";

const Header = ({ onBackClick, title, drection = 0 }) => {

  return (
    <div className="Header">
    <div className="box-header">
        {!drection && <div className="back" onClick={onBackClick} ></div> }
        <label className="lbl_header">{title}</label>
        {!!drection &&  <div className="close" onClick={onBackClick} ></div> }
    
    </div>
</div>

  );
};

export default Header;
