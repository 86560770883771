// import  './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "./login/Login.js";
import Main from './Menu.js';
import MarkingList from "./marking/MarkingList.js";
import MarkingList2 from "./marking2/MarkingList2.js";

import MarkingSt from "./marking/MarkingSt.js";
import MarkingApp from "./marking/MarkingApp.js";
import MarkingApp2 from "./marking2/MarkingApp2.js";
import MarkingStudyApp from "./markingStudy/MarkingStudyApp.js";
import MarkingStudyApp2 from "./markingStudy2/MarkingStudyApp2.js";
import MarkingFastApp from "./markingFast/MarkingFastApp.js"
import SvgDraw from './drawSvg/draw.js';
import SvgDrawMouse from './drawSvg/drawMouse.js';

import OdapNoteApp from './odapNote/OdapNoteApp.js';

import MarkingOneApp from "./markingOne/MarkingOneApp.js";
import MarkingListOne from "./markingOne/MarkingListOne.js";

import MarkingOneApp2 from "./markingOne2/MarkingOneApp2.js";
import MarkingListOne2 from "./markingOne2/MarkingListOne2.js";



function App() {
  return (
    <div className="App">
      <Routes>     
        <Route path="/" element={<Login />} />
        <Route path="/main" element={<Main />} />
        <Route path="/markinglist" element={<MarkingList />} />
        <Route path="/markinglist2" element={<MarkingList2 />} />

        <Route path="/markingst" element={<MarkingSt />} />
        <Route path='/markingapp' element={<MarkingApp />}/>
        <Route path='/markingapp2' element={<MarkingApp2 />}/>

        <Route path='/markingfastapp' element={<MarkingFastApp />} />
        <Route path='/markingstudyapp' element={<MarkingStudyApp />} />
        <Route path='/markingstudyapp2' element={<MarkingStudyApp2 />} />
        <Route path='/svgdraw' element={<SvgDraw />} />
        <Route path='/svgdrawmouse' element={<SvgDrawMouse />} />
        <Route path='/odapnoteapp' element={<OdapNoteApp />} />
       

        <Route path='/markingoneapp' element={<MarkingOneApp />} />
        <Route path="/markinglistone" element={<MarkingListOne />} />

        <Route path='/markingoneapp2' element={<MarkingOneApp2 />} />
        <Route path="/markinglistone2" element={<MarkingListOne2 />} />


        
      </Routes>
    </div>
  );
}

export default App;
