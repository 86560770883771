import styles from "./markingTitleBarOne.css"; 
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useCallback } from "react";
import MarkingMenuSpecial from "../marking/MarkingMenuSpecial";
import { useNavigate } from "react-router-dom";
import MemoCanvasApp from "../drawCanvas/MemoCanvasApp";
import { useRef, forwardRef, useImperativeHandle } from 'react';
// import ShowDap from "./ShowDap";
import ElapsedTime  from "./ElapsedTime";

const MarkingTitleBarOne = forwardRef(({ 
  no, 
  info,
  selectedList,
  testNm,
  showModal,setShowModal,
  fncPrev, 
  fncNext,
  slideHandlePrevNext, setSlideHandlePrevNext,
  setIsShowList,
  selectedRow,
  onMarkingNumber,
  onMarkingInput,
  onMarkingImport,
  onFncChejum,
  isStudy,
  importValue,
  mistakeValue,
  conceptLackValue,
  isMarkinged,
  setImgViewState,
  isShowOX, setIsShowOX,
  OX, setOX,
  markingNum,
  setMarkingNum, 
  isActiveQue, setIsActiveQue, 
  kind, setKind,  }, ref) => {

  // const [kind, setKind] = useState('que');
  const [save, setSave] = useState(null);
  // const [isActiveQue, setIsActiveQue] = useState(false);
  const [isActiveSol, setIsActiveSol] = useState(false);
  const [isActiveDap, setIsActiveDap] = useState(false);
  const [isBtnCloseDisabled, setIsBtnCloseDisabled] = useState(null);
  const [inputValue, setInputValue] = useState(''); 
  const [isShowSpecial, setIsShowSpecial] = useState(false);
  // const [isShowOXmemo, setIsShowOXmemo] = useState(false);
  // const [elapsedTime, setElapsedTime] = useState(0);
  // const [markingNum2, setMarkingNum2] = useState(null); // 상태 초기화

  const memoCanvasAppRef = useRef();
  const elapsedTimeRef = useRef(null);
    
  //------------------------------------------------------------------
  console.log(selectedList, '-------------------selectedList');


  useEffect(() => {
    setOX(selectedList['OX']);
    // fncKindQue();//풀이 또는 답일 때 문제로 초기화(여기서하면 테이블 클릭시 에러난다)
    // handleReset(); 좌우로 슬라이드 할 때 에러나
    // handleStart();
    fnc문풀답_active(kind);
  }, [selectedList]);


  function fnc문풀답_active(kind) {//슬라이드시 버튼 문제로 검은색
    if (kind == 'que') {
      setIsActiveQue(true); 
      setIsActiveSol(false); 
      setIsActiveDap(false)
    }  
  }
 
  function fncShowMemo() {
      setShowModal(true);
  }

  function fncKindQue() {
    fncSelectQueSol('que');
    setImgViewState('문제');
    setIsActiveQue(true); 
    setIsActiveSol(false); 
    setIsActiveDap(false); 
    setIsShowOX(false);
  }

  function fncKindSol() {
    fncSelectQueSol('sol');
    setImgViewState('풀이');
    setIsActiveQue(false); 
    setIsActiveSol(true); 
    setIsActiveDap(false); 
    setIsShowOX(false);
  }

  function fncKindDap() {
    setIsActiveQue(false); 
    setIsActiveSol(false); 
    setIsActiveDap(true); 
    setImgViewState('정답');//이거 용도 확인?
  }

  function fncSave() {
    setSave(true);
    setIsBtnCloseDisabled(true);//닫기가 렉걸려서

  }

  

  function fncPrevMemo() {
    fncPrev();
  }


  function fncNextMemo() {
    fncNext();   
  }


  function fncOnMarkingInput() {
    onMarkingInput(selectedRow, inputValue)
    setMarkingNum(inputValue);
    // setInputValue('');
  }


  function fncOnMarkingImport() {  
      const kind = 'import';
      const value = !importValue ? '중요' : '';   
      onMarkingImport(kind, value);//MarkingMenuOne의 fncUpdateImportant 완료후 setImport변경함 
  }

  function fncOnMarkingMistake() {  
    const kind = 'mistake';
    const value = !mistakeValue ? '실수' : '';
    onMarkingImport(kind, value);
  }

  function fncOnMarkingConceptLake() {  
    const kind = 'concept';
    const value = !conceptLackValue ? '개념' : '';
    onMarkingImport(kind, value);
  }
  

  const fncInputValueChange = useCallback((e) => {
    setInputValue(e.target.value);
}, []); // 의존성 배열에 아무것도 넣지 않아서 함수는 한 번만 생성되고 메모이제이션됩니다.



const fncSelectQueSol = (kindValue) => {
  memoCanvasAppRef.current.fncSelectQueSol(kindValue);//que or sol
  setKind(kindValue);//이걸 안해서 문풀 버튼 클릭 시 꼬였다 (고생 함)
}


function fncClose() {
  fncSelectQueSol(kind);//닫기 전에 저장
  setShowModal(false);
}

// const fncSelectQueSol = (kindValue) => {
//   // memoCanvasAppRef.current가 존재하고, fncSelectQueSol 메소드가 있는지 확인
//   if (memoCanvasAppRef.current && typeof memoCanvasAppRef.current.fncSelectQueSol === 'function') {
//     memoCanvasAppRef.current.fncSelectQueSol(kindValue);
//   } else {
//     // 에러 처리 로직, 적절한 에러 메시지나 대체 동작을 여기에 추가
//     console.error('memoCanvasAppRef.current is null or fncSelectQueSol is not a function');
//   }
//   // setKind 함수 호출
//   setKind(kindValue);
// }


const toggleSpecial = () => {
  setIsShowSpecial(prevState => !prevState);
};

const fncNoBgColorClassName = (OX) => {
  if (OX === 'O') return 'bg-green';
  if (OX === 'X') return 'bg-red';
  return '';
};


const fncNoBgColorClassName2 = (OX) => {
  // alert(OX);
  if (OX === 'O') return 'bg-green';
  if (OX === 'X') return 'bg-red';
  return '';
};

const handleStart = () => {
  elapsedTimeRef.current.startTimer();
};

const handleStop = () => {
    if (elapsedTimeRef.current) {
        elapsedTimeRef.current.stopTimer();
    }
};


const handleReset = () => {
  elapsedTimeRef.current.resetTimer();
};



useImperativeHandle(ref, () => ({
  fncNoBgColorClassName: fncNoBgColorClassName2,
  onFncChejum: onFncChejum,
 
}));

  return (
    <div className="MarkingTitleBarOne">
      <div className="box-topMenu">

        <div className="box_info">{/*NO, OX, 정답률, 경과일*/}
          {<span className={`lbl_number ${fncNoBgColorClassName(OX)}`}>{no}</span>}
          {isMarkinged && <span className="lbl_marking_info_one">{info.OX}</span>}
          {isMarkinged && <span className="lbl_marking_info_one">{info.incorrectRate}</span>}
          {isMarkinged && <span className="lbl_marking_info_one">{info.elapsedDays}</span>}
          {!isMarkinged && <ElapsedTime ref={elapsedTimeRef} />}
          {false && <input type="button" className={`btnWrongType`} onClick={() => {handleStart();}} value='시작'/>}
          {false && <input type="button" className={`btnWrongType`} onClick={() => {handleStop();}} value='중지'/>}
         
        </div>

        {<span className="lbl_marking_num">{markingNum}</span>}

        <div className="box_btnMenu"> {/*중요, 실수, 개념, 문, 풀, 답, X */}     
        
            {isMarkinged && <input type="button" className={`btnWrongType ${importValue ? "btnActive" : ""} `} onClick={fncOnMarkingImport} value='중요'/>}
            {isMarkinged && <input type="button" className={`btnWrongType ${mistakeValue ? "btnActive" : ""} `} onClick={fncOnMarkingMistake} value='실수'/>}
            {isMarkinged && <input type="button" className={`btnWrongType ${conceptLackValue ? "btnActive" : ""}`} onClick={fncOnMarkingConceptLake} value='개념'/>}
            
            {isMarkinged && <input type="button" className={`btnque ${isActiveQue ? 'active' : ''}`} value="문" 
            onClick={() => {
              setImgViewState('문제'); 
              setIsShowOX(false); 
              setIsActiveQue(true); 
              setIsActiveSol(false); 
              setIsActiveDap(false);}} />}
            
            {isMarkinged && <input type="button" className={`btnsol ${isActiveSol ? 'active' : ''}`} value="풀" 
            onClick={() => {
              setImgViewState('풀이'); 
              setIsShowOX(false); 
              setIsActiveSol(true); 
              setIsActiveQue(false); 
              setIsActiveDap(false); }} />}
            
            {isMarkinged && <input type="button" className={`btnsol ${isActiveDap ? 'active' : ''}`} value="답" 
            onClick={() => {
              // setImgViewState('풀이'); //사용하나?  이거하면 풀이로 변경된다.
              setIsShowOX(!isShowOX); 
              setIsActiveDap(true); //버튼 색 Active임
              setIsActiveQue(false); 
              setIsActiveSol(false);
   
              // fncKindDap();
              
              }} />}
            
           {false && <input type="button" className="btnMemo"  onClick={fncShowMemo} /> } 
           {/* 메모버튼 죽임 */}

        </div>


        {/* { isMarkinged && isShowOX && <ShowDap 
                        info={info}
                        setIsShowOXmemo={setIsShowOX} />} */}
        
      </div>

      {/* modeal-------------------------------------------------------------------------------모달*/}

      {false && showModal && (
        <div className="modal-overlay">
          <div className="modal-content">     
            <div className="box-header-memo">

             <div className="box-OX">          

                  <div className="box_info">{/*NO, OX, 정답률, 경과일*/}
                      <span className={`lbl_number ${fncNoBgColorClassName(OX)}`}>{no}</span>
                      {isMarkinged && <span className="lbl_marking_info_one">{info.OX}</span>}
                      {isMarkinged && <span className="lbl_marking_info_one">{info.incorrectRate}</span>}
                      {isMarkinged && <span className="lbl_marking_info_one">{info.elapsedDays}</span>}
                      {!isMarkinged && <ElapsedTime ref={elapsedTimeRef} />}
                  </div>

                  {<span className="lbl_marking_num">{markingNum}</span>}  

                  <div className="box_btnMenu">  {/*중요, 실수, 개념, 문, 풀, 답, X */}          
                        {isMarkinged && <input type="button" className={`btnWrongType ${importValue ? "btnActive" : ""} `}  onClick={fncOnMarkingImport} value='중요'/>}
                        {isMarkinged && <input type="button" className={`btnWrongType ${mistakeValue ? "btnActive" : ""} `} onClick={fncOnMarkingMistake} value='실수'/>}
                        {isMarkinged && <input type="button" className={`btnWrongType ${conceptLackValue ? "btnActive" : ""} `} onClick={fncOnMarkingConceptLake} value='개념'/>}
                      
                        {false && <input type="button" className="btnSave-memo" value='저장' onClick={fncSave} disabled={isBtnCloseDisabled} /> }
                        {false && <input type="button" className="btnPrev" value="<" onClick={fncPrevMemo}/> }
                        {false && <input type="button" className="btnNext" value=">" onClick={fncNextMemo}/> }
                      
                        {isMarkinged &&  ( <input type="button" className={`btnque ${isActiveQue ? 'active' : ''}`} value="문" 
                        onClick={fncKindQue} />)}
                      
                        {isMarkinged &&  ( <input type="button" className={`btnsol ${isActiveSol ? 'active' : ''}`} value="풀" 
                        onClick={fncKindSol} />)}
                        
                        {isMarkinged && <input type="button" className={`btnsol ${isActiveDap ? 'active' : ''}`} value="답" 
                        onClick={() => {
                          setIsShowOX(!isShowOX); 
                          fncKindDap();
                        }} />} 
                    
                        <input type="button" className="btnClose-memo" onClick={fncClose} disabled={isBtnCloseDisabled} />
                  

                  </div>
              </div>


              {/* ------------------------------------- 1, 2, 3, 4, 5, ? 채점 */}
              <div className="box-btnNumer">
            
                {['1', '2', '3', '4', '5', '?'].map((num) => (
                <button
                  key={num}
                  className={`btn_numberOne ${markingNum === num ? 'btnSelected' : ''}`}
                  onClick={() => {onMarkingNumber(selectedRow, num); setMarkingNum(num); }} > {num} </button>
                ))}
                
                 
                 
                  <button className="btn_chejum_memo " onClick={onFncChejum }>채점</button>
                  <input type="text" className="txtMark" value={inputValue} onChange={(e) => {setInputValue(e.target.value); ; setMarkingNum(e.target.value);}} autoComplete="off" />
                  <button className="btnCls" onClick={() => {setInputValue('')}}>cls</button>
                  <button className="btnInsertMark" onClick={fncOnMarkingInput}>넣기</button>
                  <input type="button" className="btnSpecial" onClick={toggleSpecial} value='※'  />
              </div>
             


              
            
            </div>
          
           

            <MemoCanvasApp 
                selectedList={selectedList}
                kind={kind}
                save={save}
                setSave={setSave}
                setShowModal={setShowModal}
                setIsBtnCloseDisabled={setIsBtnCloseDisabled}
                fncPrev={fncPrev}
                fncNext={fncNext}
                slideHandlePrevNext={slideHandlePrevNext} setSlideHandlePrevNext={setSlideHandlePrevNext}
                setIsShowList={setIsShowList}
                ref={memoCanvasAppRef}
                height={`calc(100vh - 9.5rem)`}  />
            
            { isShowSpecial && (<MarkingMenuSpecial 
                setInputValue={setInputValue} 
                isShowSpecial={isShowSpecial}
                setIsShowSpecial={setIsShowSpecial} />)}


          </div>
        </div>
      )}
    </div>
  );
});

export default MarkingTitleBarOne;
