import styles from './markingList2.scss';//css는 Top에 와야..
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header';
import Cookies from 'js-cookie'; 
import fnc from '../mymod/commFunction';
import { setScrollPosition2 } from "../reduxToolkit/redux";
// import useWarnBeforeLeaving from '../comm/useWarnBeforeLeaving';

function MarkingList2() {
    const svrUrl = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scrollPosition = useSelector((state) => state.reduxStore.scrollPosition2); 
    const [list, setList] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isMarked, setIsMarked] = useState(false); // 채점 여부를 저장할 상태
    const [testCode, setTestCode] = useState(null);
    const [testCodeMun, setTestCodeMun] = useState(null);
    const [testNm, setTestNm] = useState(null);
    const [munCnt, setMunCnt] = useState(null);
    const [dapBar, setDapBar] = useState(null);
    const [markingBar, setMarkingBar] = useState(null);
    const [oxBar, setOxBar] = useState(null);
    const listRef = useRef(null);//스크롤 유지

    // useWarnBeforeLeaving(true);//이건 사이트를 새로고침하거나 X로 닫기전에 메세지 띄움

    
    useEffect(() => {//formLoad
      fncSelectList();
    }, []);
  
    useEffect(() => {
      const lastSelectedItem = Cookies.get('lastSelectedItem');
      if (list && lastSelectedItem) {
        if (listRef.current) {//스크롤 유지
          listRef.current.scrollTop = scrollPosition;
        }
      }
    }, [list]);

  
    function fncSelectList() {   
      let stCode = fnc.getCookie('stCode');
      // const db = fnc.getCookie_pure('acaDbName');    
      sendAxios('/markinglist', { stCode }, (response) => {
        if (response) {
          setList(response);
        } else {
          console.log("속성이 obj 내에 없습니다.");
        }
      });
    }
    
    function handleItemClick(examData) {
      setTestCode(examData.시험지코드);
      setTestCodeMun(examData.시험지코드mun);
      setTestNm(examData.시험지명);
      setMunCnt(examData.문항수);
      setSelectedItem(examData.시험지코드);//배경색 변경
      setDapBar(examData.정답);
      setOxBar(examData.OX);
      setMarkingBar(examData.마킹);

      if (examData.채점=== 'Y') {
        setIsMarked(true);
      } else {
        setIsMarked(false);
      }
      dispatch(setScrollPosition2(listRef.current.scrollTop));//스크롤 유지
    }
  
    function btnMarkingClicked() {
        if (testCode === null) {
          alert('대상을 선택하세요!');
          return false;
        }
        navigate('/markingapp2', { state: { testCodeMun, testCode, testNm, munCnt }});
    }
  
    
  function btnMarkingStudyClicked() {
    if (testCode === null) {
      alert('대상을 선택하세요!');
      return false;
    }
    navigate('/markingstudyapp2', { state: { testCodeMun, testCode, testNm, munCnt, dapBar, markingBar, oxBar}});
}


    function btnMarkingFastClicked() {
      if (testCode === null) {
        alert('대상을 선택하세요!');
        return false;
      }
      navigate('/markingfastapp', { state: { testCodeMun, testCode, testNm, munCnt }});
  }

    const sendAxios = async (url, data, callback) => {
        try {
          const response = await axios.post(`${svrUrl}${url}`, data);
          let jsonObj = response.data;
          callback(jsonObj);
        } catch (error) {
          console.error("Axios 요청 중 오류가 발생했습니다:", error);
        }
      };
    
  
    return (
      <div className='MarkingList2'>
        <div className="header-container">
            <Header onBackClick={() => navigate("/main")} title="시험목록" />
        </div>  

        <div className='wrap-list-markingList' ref={listRef}>
            {
                list && list.map((examData) => (  //JSX 문법 리스트를 출력할 때 중괄호를 사용해야       
                    
                <div 
                    className={`row-list ${selectedItem === examData.시험지코드 ? 'selected' : ''}`} 
                    key={examData.시험지코드} 
                    onClick={() => handleItemClick(examData)}
                    style={selectedItem === examData.시험지코드 ? { backgroundColor: 'rgba(100,100,10,0.1)'  } : {}}
                
                >
                      
                        <div className="col-list">
                            <div className="lbl1-1">
                            <label className={`lbl ${examData.채점 === 'N' ? 'lbl-red' : ''}`}>시험명</label>
                            <label className="lbl_content" >{examData.시험지명}</label>
                            </div>
                        </div>
                        
                        <div className="col-list">
                            <div className="lbl2-1">
                            <label className={`lbl ${examData.채점 === 'N' ? 'lbl-red' : ''}`}>출제일</label>
                            <label>{examData.시험일자.replaceAll('-','.')}</label>
                            </div>

                            <div className="lbl2-2">
                            <label className={`lbl ${examData.채점 === 'N' ? 'lbl-red' : ''}`}>담임</label>
                            <label className="lbl2-2" >{examData.담임}</label>
                            </div>
                        </div>
                
                        <div className="col-list">
                            <div className="lbl2-1">
                            <label className={`lbl ${examData.채점 === 'N' ? 'lbl-red' : ''}`}>문항수</label>
                            <label> {examData.문항수} 문항</label>
                            </div>
                            <div className="lbl2-2">
                            <label className={`lbl ${examData.채점 === 'N' ? 'lbl-red' : ''}`}>채점</label>
                            <label className="lbl2-2"> {examData.채점}</label>
                            </div>
                        </div>
                      
                        {examData.채점 !== 'N' && (
                            <div className="col집계">
                                <div className="lbl집계">점수</div>
                                <label className="집계" style={{ color: '#0099FF' }}>
                                    {examData.점수}
                                </label>
                                <div className="lbl집계">석차</div>
                                <label className="집계" style={{ color: 'Red' }}>
                                    {`${examData.석차}/${examData.총원}`}
                                </label>
                                <div className="lbl집계">평균</div>
                                <label className="집계" style={{ color: '#009933' }}>
                                    {examData.평균}
                                </label>
                                <div className="lbl집계">SD</div>
                                <label className="집계" style={{ color: '#666699' }}>
                                    {examData.SD}
                                </label>
                            </div>
                        )}

            
                </div>//end-row-list

                ))
            }
        
        </div>

         <div className="footer1">
            <div className="menu">
                {<button className="menubtn" id="btn새로고침" onClick={() => fncSelectList()}>새로고침</button>}
                {!isMarked && <button className="menubtn" id="btn빠른마킹" onClick={btnMarkingFastClicked}>빠른마킹</button>}
                {!isMarked && <button className="menubtn" id="btn응시" onClick={btnMarkingClicked}>응시</button>}
                {isMarked && <button className="menubtn" id="btn학습" onClick={btnMarkingStudyClicked}>학습</button>}                      
            </div>
        </div>
  
      </div>
  
 
  );
  }
  
  export default MarkingList2;