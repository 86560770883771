import styles from "./markingTableOne.css"; //css는 Top에 와야..
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, memo} from "react";
import { useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
// import { animated, useSpring } from "react-spring";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


const svrUrl = process.env.REACT_APP_SERVER_URL;

const MarkingTableOne = (({ 
  isShowList, 
  list, 
  markingList, 
  selectedRow,
  setList, 
  setSelectedRow, 
  setNo, 
  setInfo,
  setImportValue,
  setMistakeValue,
  setConceptLackValue,
  setIsMarkinged,
  setIsShowOX,
  setMarkingNum,
}) => {

  const location = useLocation();
  const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;
  const [listTable, setListTable] = useState(null);

  console.log("2-----------------MarkingTableOne" );

  useEffect(() => {//폼 로드
    fncSelectList(); 
   
  }, []);

  function fncSelectList() {
  
    let stCode = fnc.getCookie('stCode');
    sendAxios("/markingStListOne", { testCodeMun, testCode, stCode }, (response) => {
      if (response) {  
        setList(response);
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


  

function fncSelectRow(item) {//3군데 바꿔야 함(Table, Menue, App)
  if (!item) return false;
    setSelectedRow(item.no);
    setNo(item.no + '번');

    const OX = item.OX;
    const 정답률 = item.정답률;
    const 경과일 = item.경과일;
    const dap = item.정답;
    const markingValue = item.마킹;
    setInfo({ OX: OX, incorrectRate: 정답률, elapsedDays: 경과일, dap: dap, markingValue: markingValue });

    setImportValue(item.중요);
    const mistake = (item.기록 === '실수') ? '실수' : '';
    const concepLake = (item.기록 === '개념') ? '개념' : '';
    setMistakeValue(mistake);
    setConceptLackValue(concepLake);
    setIsMarkinged(item.OX);//OX값이 있냐 공백이냐
    setIsShowOX(false);//초기화
    setMarkingNum(item.마킹);

}
  
  const sendAxios = async (url, data, callback) => {
    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };


//========================================================================= return
return (
  <div>
    {isShowList && (
      <div className="MarkingTableOne">
        <div className="wrap-list-markingSt">
          <table>
            <thead>
              <tr>
                <th style={{ width: "5rem" }}>번호</th>
                <th style={{ width: "auto" }}>마킹</th>
                <th style={{ width: "5rem" }}>OX</th>
                <th style={{ width: "5rem" }}>정답률</th>
                <th style={{ width: "5rem" }}>횟수</th>
                <th style={{ width: "5rem" }}>중요</th>
                <th style={{ width: "5rem" }}>이유</th>
                <th style={{ width: "5rem" }}>경과일</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, index) => (
                  <tr
                    className={`row-list ${selectedRow === item.no ? "selected activeRow" : ""}`}
                    key={item.no}
                    onClick={() => fncSelectRow(item)} >
                      
                    <td>{item.no}</td>
                    <td style={{textAlign: 'left', paddingLeft: '1rem'}}>{item.마킹 || ""}</td>
                    <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.OX || ""}</td>
                    <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.정답율 || ""}</td>
                    <td style={{ textAlign: 'center', paddingLeft: '1rem' }}>
                            {item.cnt === 0 ? '' : `${item.cntO}/${item.cnt}`}
                    </td>
                    <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.중요 || ""}</td>
                    <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.기록 || ""}</td>
                    <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.경과일 || ""}</td>

                </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )}
  </div>
);
});

export default MarkingTableOne;