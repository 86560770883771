import "./markingStudyApp2.scss"; 
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import MarkingTable2 from "./MarkingStudyTable2";
import MemoCanvasApp2 from "../drawCanvas/MemoCanvasApp2";


import { useSelector, useDispatch } from 'react-redux';
import {  addInfo, setIsShowList, setIsCommBoxVisible, 
          setList, setSelectedRow, setSelectedRowOld, setKind, 
          setIsShowOX, setIsActiveQueSolDap, initializeQue} from "../reduxToolkit/redux";
import fnc from "../mymod/commFunction";
import ShowDap from "../comm/ShowDap";

const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingStudyApp2() {
  const navigate = useNavigate(); 
  const location = useLocation();
  const testNm = location.state?.testNm;



  const stCode = fnc.getCookie('stCode');
  const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;

  const [no, setNo] = useState("1");
  // const [kind, setKind] = useState('que');
  // const [isActiveQueSolDap, setIsActiveQueSolDap] = useState(false);
  const [isStudy, setIsStudy]= useState(true);


  const [markingBarArr, setMarkingBarArr] = useState([]);
  //-----------------------------------------------------------------
  const isShowList = useSelector(state => state.reduxStore.isShowList);
  const list = useSelector(state => state.reduxStore.list);
  const selectedRow = useSelector(state => state.reduxStore.selectedRow);
  const isActiveQueSolDap = useSelector(state => state.reduxStore.isActiveQueSolDap);
  const isShowOX = useSelector(state => state.reduxStore.isShowOX);
  
  const [OX, setOX] = useState('');//이걸로 동기화
  const dispatch = useDispatch();

  const [importValue, setImportValue] = useState(null);
  const [mistakeValue, setMistakeValue] = useState(null);
  const [conceptLackValue, setConceptLackValue] = useState(null);
  const [selectKindActive, setSelectKindActive] = useState(null);
  // const [isShowOX, setIsShowOX] = useState(false);
  const [info, setInfo] = useState({OX: null, dap: null, markingValue: null});


  const [list2, setList2] = useState([]);
  const [selectedList, setSelectedList] = useState({});
  const [isLoadImgComplete, setIsLoadImgComplete]= useState(true);


  useEffect(() => {
    const markingBar = location.state?.markingBar;
    setMarkingBarArr(markingBar.split("|"));
    const selectKind = '전체';
    fncSelectList(selectKind)
    dispatch(setIsShowList(false));//테이블 닫기 초기화
    dispatch(initializeQue());
  }, []);


  useEffect(() => {
    dispatch(addInfo({ key: 'munCnt', value: list.length }));//이건 필요 함
  }, [list]);


  useEffect(() => {

    if (list2.length > 0 && selectedRow > 0) {
          setNo(selectedRow);
          setOX(list2[selectedRow - 1].OX);
          setImportValue(list2[selectedRow - 1].중요);
          const mistake = (list2[selectedRow - 1].기록 === '실수') ? '실수' : '';
          const concepLake = (list2[selectedRow - 1].기록 === '개념') ? '개념' : '';
          setMistakeValue(mistake);
          setConceptLackValue(concepLake);
          setSelectedList(list[selectedRow-1]);            
    }
  }, [list2, selectedRow]);

  
  function fncSelectList(selectKind) {
    fncSelectMarkingStudyList(stCode, testCodeMun, testCode, selectKind);
    setSelectKindActive(selectKind);
    dispatch(setIsCommBoxVisible(false));
  }


 const fncSelectMarkingStudyList = async (stCode, testCodeMun, testCode, selectKind) => {
    try {
      const response = await axios.post(`${svrUrl}/markingStudyList`, { stCode, testCodeMun, testCode, selectKind });
      let jsonObj = response.data;
          dispatch(setList(jsonObj));
          setList2(jsonObj);
          // 초기화 여기도 해야 전체 맞음 틀림 선택 후 테이블 row선택시 OX에러 안 난다.
          setIsShowList(false);
          dispatch(setSelectedRowOld(0));
          dispatch(setSelectedRow(1));  
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };


const fncNoBgColorClassName = (OX) => {
    if (OX === 'O') return 'bg-green';
    if (OX === 'X') return 'bg-red';
    return 'bg-red';
};


  
const sendAxios = async (url, data, callback) => {//이건 하나만 있으면 됨
  try {
    const response = await axios.post(`${svrUrl}${url}`, data);
    let jsonObj = response.data;
    callback(jsonObj);
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};

function fncOnMarkingImport() {  
  const kind = 'important';
  const value = !importValue ? '중요' : ''; 
  fncCheckImportant(kind, value);
}

function fncOnMarkingMistake() {  
const kind = 'mistake';
const value = !mistakeValue ? '실수' : '';
fncCheckImportant(kind, value);
}

function fncOnMarkingConceptLake() {  
const kind = 'concept';
const value = !conceptLackValue ? '개념' : '';
fncCheckImportant(kind, value);
}

//--------------------------------------------------------------------------/중요/실수/개념

function fncCheckImportant(kind, value) {//한 개로 통합(import, mistake, conceptLake)
  const currentDate = new Date();
  const studyDay = currentDate.toISOString().split('T')[0];// 날짜를 YYYY-MM-DD 형식으로 변환합니다.
  const munCode= selectedList.문항번호;       

  fncUpdateImportant_test(stCode, studyDay, munCode, value, kind, testCode )
}


function fncUpdateImportant_test(stCode, studyDay, munCode, markingValue, kind, testCode ) {
  sendAxios("/updateImportant_test", {stCode, studyDay, munCode, markingValue, kind, testCode  }, (response) => {

  if (response) {

    const result = response.result;
    if (result === 'Y') {
   
      if(kind ==='important') {//important import 이거 때문에 고생 함
     
        const updatedList = fnc.updateListItem(list2, selectedRow -1, '중요', markingValue);
        setList2(updatedList); // 업데이트된 리스트로 상태 갱신
        setImportValue(markingValue);

      }else if (kind ==='mistake') {
        const updatedList = fnc.updateListItem(list2, selectedRow -1, '기록', markingValue);
        setList2(updatedList); // 업데이트된 리스트로 상태 갱신

        setMistakeValue(markingValue);
        setConceptLackValue('');
      }else if (kind === 'concept') {
        const updatedList = fnc.updateListItem(list2, selectedRow -1, '기록', markingValue);
        setList2(updatedList); // 업데이트된 리스트로 상태 갱신

        setConceptLackValue(markingValue);
        setMistakeValue('');
      }
      
    }    
  } else {
    console.log("속성이 obj 내에 없습니다.");
  }
});
}

// --------------------------------------------------------------------------문풀답

function fncKindQue() {

  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)
  // setIsActiveQueSolDap('que');
  dispatch(setIsActiveQueSolDap('que'));//슬라이드 시 이걸로 초기화 함
  fncSelectQueSol('que');
  setIsShowOX(false);
  dispatch(setIsCommBoxVisible(false));
  dispatch(setKind('que'));
}

function fncKindSol() {

  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)

  // setIsActiveQueSolDap('sol');
  dispatch(setIsActiveQueSolDap('sol'));
  fncSelectQueSol('sol');
  setIsShowOX(false);
  dispatch(setIsCommBoxVisible(false));
  dispatch(setKind('sol'));
}

function fncKindDap() {
  dispatch(setIsActiveQueSolDap('dap'));
  setInfo({OX:  list2[selectedRow-1]['OX'], dap:  list2[selectedRow-1]['정답'], markingValue: markingBarArr[selectedRow - 1]})//show답용''
  dispatch(setIsShowOX(!isShowOX));
}

const fncSelectQueSol = (kindValue) => {
  setKind(kindValue);//이걸 안해서 문풀 버튼 클릭 시 꼬였다 (고생 함)
  dispatch(setIsShowList(false));
}

function fncTestClose() {

  const confirmed = window.confirm('학습을 종료하시겠습니까?');
  if (!confirmed) return
  //----------------------------
  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)
  // const munCode = list[selectedRow-1]['문항번호'];
  // dispatch(fncInsertSvgRedux(munCode, kind));//마우스 업할 때 저자한 걸 sql에 insert
  // dispatch(fncInsertSvgReduxComm());

  setTimeout(() => {
    navigate("/markinglist2");
  }, 200); // 1000 밀리초 후에 실행 (1초)

  setIsLoadImgComplete(true);

}


//========================================================================================

  return (
    <div className="MarkingStudyApp2">

      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={testNm} />
      </div>
      <div className="box_selectKind">
          <input type="button" className={`btnSelect ${selectKindActive === '전체' ? 'btnActive' : ''}`} value={'전체'} onClick={() => {fncSelectList('전체'); dispatch(setIsShowList(true));}} />
          <input type="button" className={`btnSelect ${selectKindActive === '틀림' ? 'btnActive' : ''}`} value={'틀림'} onClick={() => {fncSelectList('틀림'); dispatch(setIsShowList(true));}} />
          <input type="button" className={`btnSelect ${selectKindActive === '맞음' ? 'btnActive' : ''}`} value={'맞음'} onClick={() => {fncSelectList('맞음'); dispatch(setIsShowList(true));}} />
      </div>

      <div className="box-titleBar">
             
          <div className="box_noLeft">
            {selectedList && selectedList.OX && <span className={`lblNo ${fncNoBgColorClassName(selectedList.OX)}`}>{no}번</span>}
            {list2 && selectedRow && <span className="lbl_marking_info_memo">{`${selectedRow}/${list2.length}`}</span>}
            {selectedList && selectedList.OX && <span className="lbl_marking_info_memo">{`${ selectedList.OX}`}</span>}
            {selectedList && selectedList.정답율 > 0 && (
              <span className="lbl_marking_info_memo">{`정답률: ${selectedList.정답율}%`}</span>
            )}

            {/* {true && <span className="lbl_markingNum" style={{color:'Red'}}>{imgNameDev}</span>} */}

          </div>  


          {isStudy && (<div className="box-important">
                   
                  {true && <input type="button" className={`btnWrongType_important ${importValue ? "btnActive_important" : ""} `}  onClick={fncOnMarkingImport} value='중요'/>}
                  {true && <input type="button" className={`btnWrongType_mistake ${mistakeValue ? "btnActive_mistake" : ""} `} onClick={fncOnMarkingMistake} value='실수'/>}
                  {true && <input type="button" className={`btnWrongType_concept ${conceptLackValue ? "btnActive_concept" : ""} `} onClick={fncOnMarkingConceptLake} value='개념'/>}

                  {isStudy &&  ( <input type="button" className={`btnque ${isActiveQueSolDap === 'que' ? 'btnActive_qm' : ''}`} value="문제" onClick={fncKindQue} />)}
                  {isStudy &&  ( <input type="button" className={`btnsol ${isActiveQueSolDap === 'sol' ? 'btnActive_qm' : ''}`} value="풀이" onClick={fncKindSol} />)}
                  {isStudy && <input type="button" className={`btndap ${isActiveQueSolDap === 'dap' ? 'btnActive_qm' : ''}`} value="정답" 
                        onClick={() => { fncKindDap();}} />} 
                   
              </div>       
          )}


      </div>


        { !isShowList  && list.length > 0 && <MemoCanvasApp2 
                isLoadImgComplete={isLoadImgComplete} setIsLoadImgComplete={setIsLoadImgComplete}
                height={`calc(100vh - 9.5rem)`} />  }

                
        { isShowList && <MarkingTable2         
                list2={list2} list={list}
                isLoadImgComplete={isLoadImgComplete}   /> }

        { isShowOX && <ShowDap info={info} />}


      </div>
  );
}

export default MarkingStudyApp2;
