
import {configureStore} from "@reduxjs/toolkit";
import reduxReducer_slide from './reduxToolkit/redux';

export const store2 = configureStore({
    reducer : {
        // name_counter2: counterReducer_slide,
        // users: usersReducer_slide,
        // users2: users2Reducer_slide,
        reduxStore: reduxReducer_slide,
    }
});



