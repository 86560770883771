import styles from "./markingTitleBarStudy.scss"; 
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useCallback } from "react";
import MarkingMenuSpecial from "../marking/MarkingMenuSpecial";
import { useNavigate } from "react-router-dom";
import MemoCanvasApp from "../drawCanvas/MemoCanvasApp";
import { useRef } from "react";
import ShowDap from "../markingOne/ShowDap";
import fnc from '../mymod/commFunction';

function MarkingTitleBarStudy({ 
  no, 
  info,

  selectedList,
  testNm,
  showModal,setShowModal,
  fncPrev, 
  fncNext,
  slideHandlePrevNext, setSlideHandlePrevNext,
  setIsShowList,
  selectedRow,
  onMarkingNumber,
  onMarkingInput,
  isStudy,
}) 
{
  // const navigate = useNavigate();
  const memoCanvasAppRef = useRef();
  const [kind, setKind] = useState('que');
  const [save, setSave] = useState(null);
  const [isActiveQue, setIsActiveQue] = useState(false);
  const [isActiveSol, setIsActiveSol] = useState(false);
  const [isBtnCloseDisabled, setIsBtnCloseDisabled] = useState(null);
  const [inputValue, setInputValue] = useState(''); 
  const [isShowSpecial, setIsShowSpecial] = useState(false);
  const [OX, setOX] = useState();
  const [isActiveDap, setIsActiveDap] = useState(false);
  const [isShowOXmemo, setIsShowOXmemo] = useState(false);
 
  const [importValue, setImportValue] = useState(null);
  const [mistakeValue, setMistakeValue] = useState(null);
  const [conceptLackValue, setConceptLackValue] = useState(null);
  const svrUrl = process.env.REACT_APP_SERVER_URL;
  const stCode = fnc.getCookie('stCode');
  //------------------------------------------------------------------
  console.log(selectedList, '-------------------selectedList');
  
  useEffect(() => {
    if (selectedList) { // selectedList가 존재하는 경우에만 setOX를 호출
      setOX(selectedList['OX']);
    } else {
    
      setOX(null); // 예시: selectedList가 없을 때 OX를 null로 설정
    }
  }, [selectedList]);

  function fncShowMemo() {
      setShowModal(true);
  }

  function fncKindQue() {
    fncSelectQueSol('que');
    setIsActiveQue(true); 
    setIsActiveSol(false); 
    setIsActiveDap(false); 
    setIsShowOXmemo(false);
  }

  function fncKindSol() {
    fncSelectQueSol('sol');
    setIsActiveQue(false); 
    setIsActiveSol(true); 
    setIsActiveDap(false); 
    setIsShowOXmemo(false);
  }

    
  function fncKindDap() {
    setIsActiveQue(false); 
    setIsActiveSol(false); 
    setIsActiveDap(true); 
  }


  function fncSave() {
    setSave(true);
    setIsBtnCloseDisabled(true);//닫기가 렉걸려서

  }

  

  function fncPrevMemo() {
    fncPrev();
  }


  function fncNextMemo() {
    fncNext();
  }

  function fncClose() {
    // setSave(true);
    setShowModal(false);
  }

  function fncOnMarkingInput() {
    onMarkingInput(selectedRow, inputValue)
    setInputValue('');
  }

  const fncInputValueChange = useCallback((e) => {
    setInputValue(e.target.value);
}, []); // 의존성 배열에 아무것도 넣지 않아서 함수는 한 번만 생성되고 메모이제이션됩니다.



const fncSelectQueSol = (kindValue) => {
  memoCanvasAppRef.current.fncSelectQueSol(kindValue);
  setKind(kindValue);//이걸 안해서 문풀 버튼 클릭 시 꼬였다 (고생 함)
}

const toggleSpecial = () => {
  setIsShowSpecial(prevState => !prevState);
};

const fncNoBgColorClassName = (OX) => {
  if (OX === 'O') return 'bg-green';
  if (OX === 'X') return 'bg-red';
  return '';
};

function fncOnMarkingImport() {  
  const kind = 'import';
  const value = !importValue ? '중요' : '';   
  fncCheckImportant(kind, value);//MarkingMenuOne의 fncUpdateImportant 완료후 setImport변경함 
}

function fncOnMarkingMistake() {  
const kind = 'mistake';
const value = !mistakeValue ? '실수' : '';
fncCheckImportant(kind, value);
}

function fncOnMarkingConceptLake() {  
const kind = 'concept';
const value = !conceptLackValue ? '개념' : '';
fncCheckImportant(kind, value);
}


//--------------------------------------------------------------------------/중요/실수/개념
function fncCheckImportant(kind, value) {//한 개로 통합(import, mistake, conceptLake)
  const currentDate = new Date();
  const studyDay = currentDate.toISOString().split('T')[0];// 날짜를 YYYY-MM-DD 형식으로 변환합니다.
  const munCode= selectedList[selectedRow-1].문항번호;       
  // const kind = 'important';
  fncUpdateImportant(stCode, studyDay, munCode, value, kind )
}


function fncUpdateImportant(stCode, studyDay, munCode, markingValue, kind ) {
  sendAxios("/updateImportant", {stCode, studyDay, munCode, markingValue, kind  }, (response) => {
  if (response) {
    const result = response.result;
    if (result === 'Y') {
      // alert('완료되었습니다.');
      if(kind ==='import') {
        // list[selectedRow-1].중요 = markingValue;
        setImportValue(markingValue);
      }else if (kind ==='mistake') {
        // list[selectedRow-1].기록 = markingValue;
        setMistakeValue(markingValue);
        setConceptLackValue('');
      }else if (kind === 'concept') {
        // list[selectedRow-1].기록 = markingValue;
        setConceptLackValue(markingValue);
        setMistakeValue('');
      }
      
    }    
  } else {
    console.log("속성이 obj 내에 없습니다.");
  }
});
}


const sendAxios = async (url, data, callback) => {//이건 하나만 있으면 됨
  try {
    const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      callback(jsonObj);
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};


  return (
    <div className="MarkingTitleBarStudy">
      <div className="contents_number">
        <div className="contents_number_inner">
            <div className="box_noLeft">
                <span className={`lbl_number ${fncNoBgColorClassName(OX)}`}>{no}</span>
                <span className="lbl_marking_info">{`${selectedRow}/${info.cnt}`}</span>
                <span className="lbl_marking_info">{`${info.OX}`}</span>
                <span className="lbl_marking_info">{`정답률: ${info.incorrectRate}%`}</span>
            </div> 
       
        </div>
        
      </div>
  
    </div>
  );
}

export default MarkingTitleBarStudy;
