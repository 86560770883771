import styles from "./odapNoteApp.css"; //css는 Top에 와야..
import axios from "axios";
import React, { useEffect, useState, useRef, useCallback, useMemo  } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import DrawSwip from "../drawSvg/DrawSwip";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import DrawMenu from '../drawSvg/DrawMenu';
// import PanZoom from 'react-easy-panzoom';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


const svrUrl = process.env.REACT_APP_SERVER_URL;

function OdapNoteApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const testNm = location.state?.testNm;
  const [list, setList] = useState(null);
  const imgSize = 600;  // 임의의 값, 상황에 따라 수정이 필요합current니다.
  const [allowTouchMove, setAllowTouchMove] = useState(true);

  const DRAW_MODE = 'draw';
  const ERASE_MODE = 'erase';

  const [mode, setMode] = useState(DRAW_MODE); // 초기값을 DRAW_MODE로 설정했습니다.
  const colorRef = useRef('#000000'); // 초기 색상을 검정색으로 설정했습니다.
  const [currentColor, setCurrentColor] = useState('black');
  const highlightModeRef = useRef(false);

  const svgRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scaleRefs, setScaleRefs] = useState({});
  
  useEffect(() => {
    fncSelectList();
  }, []);
  

  const sendAxios = async (url, data, callback) => {
    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      console.log(jsonObj);
      callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };

  function fncSelectList() {
    const testCode = '23-09-02-124802';
    const testCodeMun = '00002309021247080001';
    sendAxios('/markingStList', { testCode, testCodeMun }, (response) => {
      if (response) {
        setList(response);
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


  function fncTestClose() {
    const confirmed = window.confirm('오답노트를 종료하시겠습니까?');
    if (confirmed) {
      navigate("/main");
    }
  }
  
  
  const [activeButton, setActiveButton] = useState(null);
  

  const handleButtonPress = useCallback((color, buttonId) => {
    console.log('Button Pressed:', color, buttonId);
    setActiveButton(buttonId);
    console.log('Active Button:', activeButton); // This will log the old state, not the updated one!
    setCurrentColor(color);
    colorRef.current = color;
    setMode(DRAW_MODE); 
    if (color === 'yellow' || color === 'greenyellow') {
      highlightModeRef.current = true;
    } else {
      highlightModeRef.current = false;
    }
  }, []); 



  
  const handleEraseClick = useCallback(() => {
    setMode(ERASE_MODE);  // 여기에 ERASE_MODE를 설정하는 코드를 추가
    setActiveButton('eraser-active'); // activeButton을 'eraser-active'로 설정
  }, [setMode, setActiveButton]);
  
 
  const undo = useCallback(() => {
    if (drawSwipRefs.current[currentIndex]) {
      drawSwipRefs.current[currentIndex].undo();
    }
  }, [currentIndex]);
  
  const clear = useCallback(() => {
    console.log('clear clicked');
    if(drawSwipRefs.current[currentIndex] && typeof drawSwipRefs.current[currentIndex].clear === 'function') {
      drawSwipRefs.current[currentIndex].clear();
    } else {
      console.error('Clear method is not available on the current DrawSwip component');
    }
  }, [currentIndex]); 

  
  const redo = useCallback(() => {
    if(drawSwipRefs.current[currentIndex] && typeof drawSwipRefs.current[currentIndex].redo === 'function') {
        drawSwipRefs.current[currentIndex].redo();
    } else {
        console.error('Redo method is not available on the current DrawSwip component');
    }
  }, [currentIndex]); // currentIndex를 dependency로 추가합니다.
  
  const zoomIn = useCallback(() => { 
    if(drawSwipRefs.current[currentIndex] && typeof drawSwipRefs.current[currentIndex].zoomIn === 'function') {
        drawSwipRefs.current[currentIndex].zoomIn();
    } else {
        console.error('zoomIn method is not available on the current DrawSwip component');
    }  
  }, [currentIndex]);

  const zoomOut = useCallback(() => { 
    if(drawSwipRefs.current[currentIndex] && typeof drawSwipRefs.current[currentIndex].zoomOut === 'function') {
        drawSwipRefs.current[currentIndex].zoomOut();
    } else {
        console.error('zoomOut method is not available on the current DrawSwip component');
    }  
  }, [currentIndex]);
  
const drawSwipRefs = useRef([]);

const handleSlideChange = (newIndex) => {
  setCurrentIndex(newIndex);
  console.log(`Moving to slide index: ${newIndex}`);
}

const settings = {
  dots: false,
  infinite: false,
  speed: 300,
  arrows: false, 
  swipe: allowTouchMove,
  touchThreshold: 5, // 스와이프 감도 설정 (높은 값으로 설정하여 길게 스와이프해야 슬라이드가 변경되게 설정)
  beforeChange: (current, next) => handleSlideChange(next), // next는 새로운 슬라이드의 index입니다.
};


return (


  <div className="OdapNoteApp">

    <div className="header-container">
      <Header onBackClick={() => fncTestClose()} title={testNm} />
    </div>

    {list ? (
      <Slider {...settings}>
        {list.map((item, index) => {
          const noIdx = item.no;
          const folder = item.폴더.replace(/\\/g, "/");
          const fileNameComm = `${item.공통지문}`;
          const fileNameQue = `${item.fileName}`;
          const imgPathComm = `https://mathautogen.i234.me/autogenEditImage/0000/${folder}/${fileNameComm}.gif`;
          const imgPathQue = `https://mathautogen.i234.me/autogenEditImage/0000/${folder}/${fileNameQue}.gif`;
          const no = `${item.no}번`;

          return (
            <div key={index} className="slider-item">

                  <div className="box-que">
                    <label className="lblComment">{no} </label>
                    <DrawSwip
                      imgUrlQue={{ imgPathQue }}
                      allowTouchMove={allowTouchMove}
                      setAllowTouchMove={setAllowTouchMove}
                      activeButton={activeButton}
                      setActiveButton={setActiveButton}
                      currentColor={currentColor}
                      mode={mode}
                      highlightMode={highlightModeRef.current}
                      ref={(el) => (drawSwipRefs.current[index] = el)}
                      index={index} // 이 부분에 index 값을 전달합니다.  
                      scaleRefs={scaleRefs}
                      setScaleRefs={setScaleRefs}           
                    />
                  </div>

            </div>
          );
        })}
      </Slider>
    ) : (
      <p>Loading...</p>
    )}

    <DrawMenu
      activeButton={activeButton}
      handleButtonPress={handleButtonPress}
      handleEraseClick={handleEraseClick}
      undo={undo}
      redo={redo}
      clear={clear}
      zoomIn={zoomIn}
      zoomOut={zoomOut}
    />
  </div>
);
}


export default OdapNoteApp;
