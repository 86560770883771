import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import indexDb from "../indexedDB/query_autogen";

const svrUrl = process.env.REACT_APP_SERVER_URL;

export const reduxSlice = createSlice({
    name: 'name_reduxSlice',//변경해도 의미 없네 (내부적으로 식별 용도인 듯)
    initialState: {
        value1: 1,
        list: [],
        list2: [],
        selectedList: {},
        selectedList2: {},
        selectedRow: 1, selectedRowOld: 1,
        slideHandlePrevNextInt: -1,
        slideHandlePrevNextInt_old: 0,
        markingList: {},
        isShowList: false,
        info:{},
        isShowDisplayOX: false,
       
        isCommBoxVisible: false,
        kind: 'que',
        currentColor: 'black', highlightMode: false,
        mode: '', undo: false, redo: false, clear: false, prev: false, next: false,
        isActiveQueSolDap: 'que',
        isShowOX: false,
        version: '',
        scrollPosition: 0,  scrollPosition2: 0, scrollPosition3: 0,
    },
    
    reducers: {
        increment: (state) => {
            state.value1 += 1;
        },
        setList: (state, action) => {
            state.list = action.payload;
            // state.list2 = state.list;
            // state.list2 = [...action.payload]; // 새로운 배열 참조 생성
        },
        setList2: (state, action) => {
            state.list2 = action.payload;
        },
        setSelectedList: (state, action) => {
            state.selectedList = action.payload;
        },
        setSelectedList2: (state, action) => {
            state.selectedList2 = action.payload;
        },
 
        setSelectedRow: (state, action) => {
            state.selectedRow = action.payload;
        },
        setSelectedRowOld: (state, action) => {
            state.selectedRowOld = action.payload;
        },
          
        setIsShowList: (state, action) => {
            state.isShowList = action.payload;
        },
        setSlideHandlePrevNextInt: (state, action) => {
            state.slideHandlePrevNextInt = action.payload;
        },
        setSlideHandlePrevNextInt_old: (state, action) => {
            state.slideHandlePrevNextInt_old = action.payload;
        },
        setMarkingList_init: (state, action) => {
            // 전체 객체를 상태로 설정
            state.markingList = action.payload;
        },
          
        initializeMarking: (state, action) => {
            state.markingList = fncInitializeMarkingList(action.payload); // action.payload는 문항 수
        },

        setMarkingList: (state, action) => {
            const { no, value } = action.payload;
            state.markingList[no] = value;
        },

       

        addInfo: (state, action) => {
            const { key, value } = action.payload;
            state.info[key] = value;
        },

        updateList: (state, action) => {
            const { Row, fieldName, newValue } = action.payload;
            if (Row > 0 && Row <= state.list.length && fieldName in state.list[Row-1]) {
                state.list[Row-1][fieldName] = newValue;
            }
        },
        updateList2: (state, action) => {
            const { Row, fieldName, newValue } = action.payload;
            // console.log( Row, fieldName, newValue,' Row2 fieldName2, newValue2');
            if (Row > 0 && Row <= state.list2.length && fieldName in state.list2[Row-1]) {
                state.list2[Row-1][fieldName] = newValue;
            }
        },
        setIsShowDisplayOX: (state, action) => {
            state.isShowDisplayOX = action.payload;
        },

     
        updateSvgArray: (state, action) => {
          state.svgArray = action.payload;
      },

     
      setIsCommBoxVisible: (state, action) => {
        state.isCommBoxVisible = action.payload;
      },
      setKind: (state, action) => {
        state.kind = action.payload;
      },
      setCurrentColor: (state, action) => {
        state.currentColor = action.payload;
    },
    setHighlightMode: (state, action) => {
        state.highlightMode = action.payload;
    },
    setMode: (state, action) => {
        state.mode = action.payload;
    },
    setIsShowList: (state, action) => {
        state.isShowList = action.payload;
    },
    setUndo: (state, action) => {
        state.undo = action.payload;
    },
    setRedo: (state, action) => {
        state.redo = action.payload;
    },
    setClear: (state, action) => {
        state.clear = action.payload;
    },
    setPrev: (state, action) => {
        state.prev = action.payload;
    },
    setNext: (state, action) => {
        state.next = action.payload;
    },
    setIsActiveQueSolDap: (state, action) => {//문제 풀이 정답 버튼 active
        state.isActiveQueSolDap = action.payload;
    },
    setIsShowOX: (state, action) => {//마킹OX 해골 숨김
        state.isShowOX = action.payload;
    },
    setVersion: (state, action) => {
        state.version = action.payload;
    },
    setScrollPosition(state, action) {
        state.scrollPosition = action.payload;
    },
    setScrollPosition2(state, action) {
        state.scrollPosition2 = action.payload;
    },
    setScrollPosition3(state, action) {
        state.scrollPosition3 = action.payload;
    },
    initializeQue: (state) => {
        state.isActiveQueSolDap = 'que';
        state.isCommBoxVisible = false;
        state.isShowOX = false;
    },

    
}


});




export const fncClickRow = (Row) => (dispatch, getState) => {
    const list = getState().reduxStore.list; // Redux store에서 list2를 가져옴(reduxStore: store.js의 이름)
    const slideHandlePrevNextInt_old = getState().reduxStore.slideHandlePrevNextInt_old;
    // console.log(slideHandlePrevNextInt_old, Row,'slideHandlePrevNextInt_oldslideHandlePrevNextInt_old');
    if (list && list.length >= Row) {
        dispatch(setSelectedList(list[Row - 1]));

        if (slideHandlePrevNextInt_old <= Row) {
            dispatch(setSlideHandlePrevNextInt(-1));
        }else {
            dispatch(setSlideHandlePrevNextInt(1));
        }

        dispatch(setSlideHandlePrevNextInt_old(Row));
    }
};


  
export const fncClickRow2 = (Row) => (dispatch, getState) => {
    const list = getState().reduxStore.list;
    const list2 = getState().reduxStore.list2; // Redux store에서 list2를 가져옴(reduxStore: store.js의 이름)
    const slideHandlePrevNextInt_old = getState().reduxStore.slideHandlePrevNextInt_old;

    if (list2 && list2.length >= Row) {
        dispatch(setSelectedList(list[Row - 1]));//canavs에서는 setSelectedList로 참조한다.
        dispatch(setSelectedList2(list2[Row - 1]));

        if (slideHandlePrevNextInt_old <= Row) {
            dispatch(setSlideHandlePrevNextInt(-1));
        }else {
            dispatch(setSlideHandlePrevNextInt(1));
        }

        dispatch(setSlideHandlePrevNextInt_old(Row));   
    }
};

  
  // 외부 함수 fncSelectMarkingStudyList
  export const fncSelectMarkingStudyList = (stCode, testCodeMun, testCode, selectKind) => async (dispatch) => {
    try {
      const response = await axios.post(`${svrUrl}/markingStudyList`, { stCode, testCodeMun, testCode, selectKind });
      let jsonObj = response.data;
      dispatch(setList(jsonObj)); // 상태 업데이트'
      dispatch(setSelectedRow(1));//초기화
      dispatch(setSelectedList(jsonObj[0]));//canavs에서는 setSelectedList로 참조한다.
      dispatch(setSelectedList2(jsonObj[0]));
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };

  
//-------------------------------------------------------24년 5월 이후 추가 함수
  // markingList 초기 상태 설정 함수(이거 안해도 에러가 안 나네 일단 스킵)
export function fncInitializeMarkingList(munCnt) {
    
    const initialState = {};
    for (let i = 1; i <= munCnt; i++) {
        initialState[i] = '';  // 각 문항의 초기 값은 빈 문자열로 설정
    }
    return initialState;
}

 
export const fncResetSlide = () => async (dispatch, getState) => {//이렇게 안 해도 됨
    // dispatch(setKind('que'));
    // dispatch(setIsActiveQueSolDap('que'));
    // dispatch(setIsCommBoxVisible(false));
    // dispatch(setIsShowOX(false));

    dispatch(initializeQue());//이렇게 해도 됨
    
}


export const { 
    increment, 
    setList, setList2, 
    setSelectedList, setSelectedList2,
    setSelectedRow, setSelectedRowOld,
    setSlideHandlePrevNextInt,setSlideHandlePrevNextInt_old,
    setMarkingList_init, setMarkingList,
    addInfo, setIsShowList, updateList, setIsShowDisplayOX,
    updateList2, 
    setIsCommBoxVisible, resetSvgComm,
    setKind,
    setCurrentColor, setHighlightMode,setMode, setUndo,setRedo,setClear,setPrev, setNext,
    setIsActiveQueSolDap,setIsShowOX,setVersion,
    setScrollPosition,setScrollPosition2,setScrollPosition3,
    initializeMarking,
    initializeQue,
} = reduxSlice.actions;

export default reduxSlice.reducer;

