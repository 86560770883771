import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

const ElapsedTime = forwardRef((props, ref) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const intervalRef = useRef(null);

    const startTimer = () => {
        if (intervalRef.current !== null) return; // 이미 타이머가 실행 중인 경우 시작하지 않음

        intervalRef.current = setInterval(() => {
            setElapsedTime(prevTime => prevTime + 1);
        }, 1000);
    };

    const stopTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const resetTimer = () => {
        stopTimer();
        setElapsedTime(0);
    };

    useImperativeHandle(ref, () => ({
        startTimer,
        stopTimer,
        resetTimer
    }));

    useEffect(() => {
        // 부모 컴포넌트에서 타이머 제어를 원할 경우 이 부분을 제거
        startTimer();
        return () => stopTimer(); // 컴포넌트 언마운트 시 타이머 정리
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return <span className="lbl_elapsedTime">{formatTime(elapsedTime)}</span>;
});

export default ElapsedTime;
