import "./markingQueOne.css"; //css는 Top에 와야..
// import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useRef  } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { Prev } from "react-bootstrap/esm/PageItem";
import DisplayOX from "./DisplayOX";
import fnc from '../mymod/commFunction';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


function MarkingQue({ 
  list, 
  selectedRow,  
  imgSize, 
  setIsShowList,
  fncPrev,
  fncNext, 
  imgViewState, setImgViewState,
  isShowOX,
  OX,
  chejumCompleted, setChejumCompleted,
}) {
  const slidePixcelSize = process.env.REACT_APP_SLIDE_PIXCELSIZE;
  const [imgUrlComm, setImgUrlComm] = useState(null);
  const [imgUrlQue, setImgUrlQue] = useState(null);
  const [imgUrlSol, setImgUrlSol] = useState(null);
  const [comment, setComment] = useState(null);

  const [fileNameComm, setFileNameComm] = useState(null);
  const [fileNameQue, setFileNameQue] = useState(null);
  
  const [dap, setDap] = useState(null);
  const [markingValue, setMarkingValue] = useState(null);
  
  const [animationClass, setAnimationClass] = useState('');
  const prevNextRef = useRef(selectedRow);
  const [startX, setStartX] = useState(null);

  // let  fileNameQue, fileNameSol = null;

  console.log('3.-------------------markingQue');

  useEffect(() => {
    fncHandleCurrent();
    fncCloseList();
    setImgViewState('문제');
  }, [selectedRow]);

  function fncCloseList() {
    setIsShowList(false);
  }

  function fncHandleCurrent() {
    if (list && selectedRow !== null) {
      const currentIndex = selectedRow; // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
      const nextItem = list[currentIndex - 1];
      fncSelectRow(nextItem);
    }
  }


function fncSelectRow(item) {
  console.log(item,'-------item');
  if (!item) return false;
  const folder = item.폴더;
  const fileNameComm = item.공통지문;
  const fileNameQue = item.fileName;
  const dap = item.정답;
  const markingValue = item.마킹
  const comment = item.코멘트

  fncShowImgQue(folder, fileNameComm, fileNameQue, dap, markingValue, comment);  

}

function fncShowImgQue(folder,fileNameComm, fileNameQue, dap, markingValue, comment) {


  folder = folder.replace(/\\/g, "/");
  fileNameComm=fileNameComm.replace('.hwp','.gif');
  fileNameQue = fileNameQue + ".gif";

  const acaCode = fnc.getCookie('acaCode');
  const imageWebPath = fnc.getCookie('imageWebPath');
  const imageWebPath_acaCode_folder = `${imageWebPath}/autogenEditImage/${acaCode}/${folder}`;

  const imgPathComm= `${imageWebPath_acaCode_folder}/${fileNameComm}`;
  const imgPathQue = `${imageWebPath_acaCode_folder}/${fileNameQue}`;
  const imgPathSol = `${imageWebPath_acaCode_folder}/풀이_${fileNameQue}`;

  // console.log(fileNameSol,'fileNameSolfileNameSolfileNameSolfileNameSol');
  
  setFileNameComm(fileNameComm);
  setFileNameQue(fileNameQue);
  setComment(comment);
  //----------------------------
  setImgUrlComm(imgPathComm);
  setImgUrlQue(imgPathQue);
  setImgUrlSol(imgPathSol);
  setDap(dap);
  setMarkingValue(markingValue);
  

  fncCloseList();
 
  const direction = Number(prevNextRef.current) - Number(selectedRow)
  handlePrevNext(direction);
  prevNextRef.current = selectedRow;
}

function fncCloseList() {
  setIsShowList(false);
}

const handlePrevNext = (direction) => {
  setAnimationClass(direction > 0 ? 'slide-in-left' : 'slide-in-right');
  // 애니메이션이 완료되면 클래스를 제거
  setTimeout(() => setAnimationClass(''), 500);
};


// 터치 시작 지점의 x 좌표를 저장합니다.
const handleTouchStart = (e) => {
  setStartX(e.touches[0].clientX);
}

// 터치 종료 지점에서의 x 좌표와 시작 지점의 x 좌표를 비교하여 슬라이드를 감지합니다.
const handleTouchEnd = (e) => {
  const endX = e.changedTouches[0].clientX;
  if (startX - endX > 100) { // 왼쪽으로 50px 이상 슬라이드한 경우
    slideToLeftFunction();
  } else if (endX - startX > 100) { // 오른쪽으로 50px 이상 슬라이드한 경우
    slideToRightFunction();
  }
}

const slideToLeftFunction = () => {
  fncNext();// console.log("왼쪽으로 슬라이드!");
}

const slideToRightFunction = () => {
  fncPrev();
}


  return (
    <div className="MarkingQueOne" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} >

      { chejumCompleted && <DisplayOX  
        OX={OX} 
        chejumCompleted={chejumCompleted} setChejumCompleted={setChejumCompleted} /> }

      <div className={`main ${isShowOX ? 'adjusted-height' : ''}`}>
        <div className={`main_inner ${animationClass}`}>
            { fileNameComm  && imgViewState == '문제'  && <img className="img-comm"  src={imgUrlComm}  style={{ width: `${imgSize}px` }} alt="공통지문 이미지" />} 
            { fileNameQue && imgViewState == '문제'  &&  <img className="img-que"  src={imgUrlQue}  style={{ width: `${imgSize}px` }} alt="문제 이미지" />}
            { fileNameQue && imgViewState == '풀이'  &&  <img className="img-sol"  src={imgUrlSol}  style={{ width: `${imgSize}px` }} alt="풀이 이미지" />}      
            { comment && imgViewState == '문제' && <label id="lblComment">{comment}</label> }
        </div>
       
      </div>

     

    </div>
  );
}

export default MarkingQue;
