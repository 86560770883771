import "./markingApp2.scss"; //css는 Top에 와야..
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import MarkingTable from "./MarkingTable2";
import Cookies from 'js-cookie';
import MemoCanvasApp2 from "../drawCanvas/MemoCanvasApp2";
import MarkingMenuSpecial from "../comm/MarkingMenuSpecial";

import { useSelector, useDispatch } from 'react-redux';
import {  setList, setMarkingList, addInfo, initializeMarking, setIsShowList } from "../reduxToolkit/redux";
import fnc from "../mymod/commFunction";


const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingApp2() {
  const navigate = useNavigate(); 
  const location = useLocation();
  const testNm = location.state?.testNm;
  const munCnt= location.state?.munCnt;
   const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;

  const [no, setNo] = useState("1");
  const [inputValue, setInputValue] = useState(''); 
  const [isShowSpecial, setIsShowSpecial] = useState(false);
  const [OX, setOX] = useState();
  const [markingNum, setMarkingNum]= useState();
  const [remainMunCnt, setRemainMunCnt]= useState();
  const [list2, setList2] = useState([]);

  const [isLoadImgComplete, setIsLoadImgComplete]= useState(true);
  const selectedRow = useSelector(state => state.reduxStore.selectedRow);
  const [selectedList, setSelectedList] = useState({});
  //-----------------------------------------------------------------
  
  const markingList = useSelector(state => state.reduxStore.markingList);
  const isShowList = useSelector(state => state.reduxStore.isShowList);
  const list = useSelector(state => state.reduxStore.list);

  const dispatch = useDispatch();

  useEffect(() => {
      fncSelectList(testCodeMun, testCode);
      dispatch(setIsShowList(false));//테이블 닫기 초기화
  }, []);

  
  const fncSelectList = async (testCodeMun, testCode)  => {
    try {
    const response = await axios.post(`${svrUrl}/markingStList`, { testCodeMun, testCode });
    let jsonObj = response.data;
        dispatch(setList(jsonObj));
        setList2(jsonObj);
        dispatch(initializeMarking(jsonObj.length));//초기화 해야해
    } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
};


  useEffect(() => {
    dispatch(addInfo({ key: 'munCnt', value: list.length }));
  }, [list]);

  
  useEffect(() => {

    setNo(selectedRow);
    if (list2.length > 0 && selectedRow > 0) {
          setMarkingNum('');//12345? 버튼 초기화
          setSelectedList(list[selectedRow-1]);   
    }
  }, [list2, selectedRow]);


  useEffect(() => {
    const listArray =  Object.values(markingList);//객체의 값들을 배열로 변환
    const countEmpty = listArray.filter(item => item === "").length;
    if (countEmpty == 0 && listArray.length == munCnt) {
      setRemainMunCnt(`완료`);
    }else {
      setRemainMunCnt(`${countEmpty}/${munCnt}`);
    }
   
  }, [markingList]);
  



const toggleSpecial = () => {
  setIsShowSpecial(!isShowSpecial);
};

const fncNoBgColorClassName = (OX) => {
if (OX === 'O') return 'bg-green';
if (OX === 'X') return 'bg-red';
return 'bg-red';
};


const fncOnMarking = (no, value) => {
  dispatch(setMarkingList({ no, value }));
  setMarkingNum(value); //1,2,3,4,5 버튼 bgColor
};

function fncOnMarkingInput(no, value) {
  setMarkingNum(value);
  dispatch(setMarkingList({ no, value }));
}


function fncTestClose() {

  const confirmed = window.confirm('시험을 종료하시겠습니까?');
  if (!confirmed) return
  //----------------------------
  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)
  
  setTimeout(() => {
    navigate("/markinglist2");
  }, 200); // 1000 밀리초 후에 실행 (1초)

  setIsLoadImgComplete(true);

}



function fncSubmit() {
  const rtn = window.confirm('시험을 제출하시겠습니까?');
  if (!rtn) return false;

  let oxArr = [];
  let dapArr = [];
  let markingArr= [];
  let Ocnt = 0;
  let Xcnt = 0;
  //const 변수는 선언과 동시에 초기화해야 합니다.
  list.forEach((item) => {
      const submittedAnswer = markingList[item.no];
      const correctAnswer = item.정답;
      
      // 배열을 문자열로 변환하여 비교
      if (JSON.stringify(submittedAnswer) === JSON.stringify(correctAnswer)) {
        oxArr.push('O');
        Ocnt++;
      } else {
        oxArr.push('X');
        Xcnt++;
      }       
      dapArr.push(correctAnswer);// 정답도 저장
      markingArr.push(submittedAnswer)
  });

  const dapBar= dapArr.join('|');
  const markingBar= markingArr.join('|');
  const oxBar= oxArr.join('|');
  let score = (Ocnt / dapArr.length) * 100;
  score = Math.round(score * 100) / 100;
 
  fncInsert_te_marking(score, Ocnt, Xcnt, dapBar, markingBar, oxBar);


}

function fncInsert_te_marking(점수, 맞은개수, 틀린개수, 정답, 마킹, OX) {
  const 시험지코드 = testCode;
  const 학생고유번호 = fnc.getCookie('stCode');

  sendAxios("/insert_te_Marking", { 시험지코드, 학생고유번호, 점수, 맞은개수, 틀린개수, 정답, 마킹, OX }, (response) => {
    if (response) {

      let result = response.result;
      if (result === 'success') {
        alert('완료되었습니다.');
        navigate('/markinglist2');
      }
      
    } else {
      console.log("속성이 obj 내에 없습니다.");
    }
  });
}

  
const sendAxios = async (url, data, callback) => {//이건 하나만 있으면 됨

  try {
    const response = await axios.post(`${svrUrl}${url}`, data);
    let jsonObj = response.data;
    callback(jsonObj);
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};


//========================================================================================

  return (
    <div className="MarkingApp2">

      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={testNm} />
      </div>


      <input type="button" className="btnSubmit" value={'제출'} onClick={() => fncSubmit()} />
    

      <div className="box-header-memo2">
          <span className={`lblNo ${fncNoBgColorClassName(OX)}`}>{no}번</span>
          {true && <span className="lbl_markingNum">{markingList[no]}</span>}
          {true && <span className="lbl_remainMunCnt">{remainMunCnt}</span>}

          {(<div className="box-btnNumer">
              
            {['1', '2', '3', '4', '5', '?'].map((num) => (
                <button
                  key={num}
                  className={`btn_number ${markingNum === num ? 'btnSelected' : ''}`}
                  onClick={() => {fncOnMarking(no, num);}} > {num} </button>
            ))}
            
              
              {false && <input type="text" className="txtMark" value={inputValue} onChange={(e) => setInputValue(e.target.value)} autoComplete="off" />}
              {false && <button className="btnCls" onClick={() => {setInputValue('')}}>cls</button>}
              {false && <button className="btnInsertMark" onClick={() => fncOnMarkingInput(no, inputValue)}>넣기</button>}
              <input type="button" className="btnSpecial" onClick={toggleSpecial} value='주관식'  />
          </div>
          )}


      </div>


        { !isShowList && list.length > 0 && <MemoCanvasApp2 
                isLoadImgComplete={isLoadImgComplete} 
                setIsLoadImgComplete={setIsLoadImgComplete}
                height={`calc(100vh - 9.5rem)`}          
          />  }
            

        { isShowList && <MarkingTable
          list2={list2} 
          isLoadImgComplete={isLoadImgComplete} 
          /> }


        { isShowSpecial && (<MarkingMenuSpecial 
                setInputValue={setInputValue} 
                isShowSpecial={isShowSpecial} 
                setIsShowSpecial={setIsShowSpecial}
                selectedRow={selectedRow} 
        />)}

      </div>
  );
}

export default MarkingApp2;
