
import "./memoCanvasApp.scss"; 
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import DrawNormal from "../drawSvg2/DrawNormal";
import DrawMenu from '../drawSvg2/DrawMenu';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { setIsShowList, setIsCommBoxVisible, setKind, setSelectedRow, setSelectedRowOld } from "../reduxToolkit/redux";

import fnc from '../mymod/commFunction';

const acaCode = fnc.getCookie('acaCode');
const imageWebPath = fnc.getCookie('imageWebPath');


const MemoCanvasApp = ({
  height,
  isLoadImgComplete, 
  setIsLoadImgComplete,
}) => {


  const [allowTouchMove, setAllowTouchMove] = useState(true);
 
  const [imgPathQue, setImgPathQue] = useState(null);
  const [imgSize, setImgSize] = useState(null);

  const [isClear, setIsClear] = useState(false);

  const dispatch = useDispatch();
  const isShowList = useSelector(state => state.reduxStore.isShowList);
  const isCommBoxVisible = useSelector(state => state.reduxStore.isCommBoxVisible);
  const list = useSelector(state => state.reduxStore.list);
  const selectedRow = useSelector(state => state.reduxStore.selectedRow);
  const selectedRowOld = useSelector(state => state.reduxStore.selectedRowOld);
  const kind = useSelector(state => state.reduxStore.kind);

  
  const fileName_comm = list[selectedRow-1]?.['공통지문'];
 

  useEffect(() => {
    if (list.length > 0 && kind) {
      fncMakeSvg(kind);
    }
  }, [kind, selectedRow]);

  const fncMakeSvg = (kind) => {
    const url = `${imageWebPath}/autogenEditImage/${acaCode}`;
    const folder = list[selectedRow-1]?.['폴더'].replace(/\\/g, '/');
    const fileNameComm = fileName_comm?.replace('.hwp', '');
    const fileName = list[selectedRow-1]?.['fileName'];
       
    const imgSizeQue = list[selectedRow-1]?.['imgSize'];
    const imgSizeSol = list[selectedRow-1]?.['imgSizeSol'];
    const imgSizeComm = list[selectedRow-1]?.['imgSizeComm'];


    let imgPath;
    if (kind === 'sol') {
      imgPath = `${url}/${folder}/풀이_${fileName}.gif`;
      setImgSize(imgSizeSol);
      dispatch(setKind('sol'));
    } else if (kind === 'comm') {
      imgPath = `${url}/${folder}/${fileNameComm}.gif`;
      setImgSize(imgSizeComm);
      dispatch(setKind('comm'));
    } else {
      imgPath = `${url}/${folder}/${fileName}.gif`;
      setImgSize(imgSizeQue);
      dispatch(setKind('que'));
    }
    setImgPathQue(imgPath);
  };


  const toggleBox_comm = () => {
    if (isCommBoxVisible){
      dispatch(setSelectedRowOld(-100))
      fncMakeSvg('que');
    }else {
      dispatch(setSelectedRowOld(100))
      fncMakeSvg('comm');
    }
    dispatch(setIsCommBoxVisible(!isCommBoxVisible));
  };

  //=======================================================================
  return (
    <div className="MemoCanvasApp2">
      <div className="box-que">
        {fileName_comm && (
          <div className='box_comm' >
            <button className={isCommBoxVisible ? 'btnToggleComm_que' : 'btnToggleComm_comm'} 
                    onClick={toggleBox_comm}> {isCommBoxVisible ? '문\n제' : '지\n문'}
            </button>
          </div>
        )}
        {(imgPathQue) && (
          <DrawNormal
            key={imgPathQue}
            imgUrlQue={{ imgPathQue: imgPathQue, selectedList: list[selectedRow - 1] }}
            setAllowTouchMove={setAllowTouchMove}
            height={height}
            imgSize={imgSize}
            isLoadImgComplete={isLoadImgComplete} 
            setIsLoadImgComplete={setIsLoadImgComplete}
            isClear={isClear}
          />
        )}
      </div>
      <DrawMenu />
    </div>
  );
};

export default React.memo(MemoCanvasApp);
