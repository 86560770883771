import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';//추가
// import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';//추가
import App from './App.js';
import { Helmet } from 'react-helmet';

import { Provider } from 'react-redux';
import {store2} from './store';
import './scss/reset.scss';
import { setVersion} from "./reduxToolkit/redux";

// DOM 노드 찾기
const root = document.getElementById('root');

// root를 사용하여 createRoot 호출
const appRoot = ReactDOM.createRoot(root);

window.AppVersion_ios = (version) => {//index.js나 index.html에서만 값을 가져올 수 있는데 index.html은 컨포넌트에 값을 전달할 수 없다.
  store2.dispatch(setVersion(version));
  //dispatch(setVersion(version));//이 방식은 안돼(함수 안에 넣어야해 최상위에 존재할 수 없대)
};

window.AppVersion = (version) => {//index.js나 index.html에서만 값을 가져올 수 있는데 index.html은 컨포넌트에 값을 전달할 수 없다.
  store2.dispatch(setVersion(version));
  //dispatch(setVersion(version));//이 방식은 안돼(함수 안에 넣어야해 최상위에 존재할 수 없대)
};


// 여기는 무조건 <App />으로 해놓고 App에서 Login을 /로 지정하면 된다.
appRoot.render(
  // <React.StrictMode>
  <Provider store={store2}>
    <BrowserRouter>
      <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0,viewport-fit=cover, user-scalable=no, maximum-scale=1.0, minimum-scale=1.0" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>

      <App /> 
    </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);
