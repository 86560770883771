import "./markingSetting.scss"; 

import React, { useState, useEffect } from 'react';
//React는 기본 내보내기(default export)가 아니라 명명된 내보내기(named export)가 아니기 때문에 중괄호 {} 안에 넣을 수 없습니다. 
// import 'bootstrap/dist/css/bootstrap.min.css';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import Button from '@mui/material/Button';

import Cookies from 'js-cookie';


function MarkingSetting({ setChkNextMoonje, handleClose }) {
  const [isNextMunjeChecked, setIsNextMunjeChecked] = useState(true);

  console.log('6.--------------------MarkingSetting');
  useEffect(() => {
    // isNextMunjeChecked 값이 변경될 때마다 setChkNextMoonje 호출
    setChkNextMoonje(isNextMunjeChecked);
  }, [isNextMunjeChecked]);  // 의존성 배열에 isNextMunjeChecked를 넣어서, 그 값이 변할 때마다 useEffect가 실행되도록 설정

  
  const handleCheckboxChange = (e) => {
    setIsNextMunjeChecked(e.target.checked);
  };

  // 컴포넌트가 마운트될 때 쿠키의 초기값을 불러옵니다.
  useEffect(() => {
    const initialCookieValue = Cookies.get('chkNextMoonje');
    if (initialCookieValue) {
      setIsNextMunjeChecked(initialCookieValue === '1');
    }
  }, []);

  // 체크박스 상태가 변경될 때마다 쿠키를 업데이트합니다.
  useEffect(() => {
    const myCookieValue = isNextMunjeChecked ? '1' : '0';
    Cookies.set('chkNextMoonje', myCookieValue, { expires: 1000 });
  }, [isNextMunjeChecked]);



  const handleSave = () => {
    const isNextMunjeChecked = document.getElementById('chkNextMoonje').checked;
    const myCookieValue = isNextMunjeChecked ? 1 : 0;
    Cookies.set('chkNextMoonje', myCookieValue, { expires: 1000 });

    handleClose();
  };

  

  return (
    <div className="MarkingSetting">

  
    <div className="container_setting" id="setting">
      <div className="setting_right_header">
        <input type="button" className="btn_back_setting" id="btn_back_setting"/>
        <div className="setting_right_header_lbl">마킹 설정</div>
            <input  type="button" className="btn_close_setting" onClick={handleClose}   />
        </div>

      <div className="setting_right_contents">
        <FormControlLabel
          control={
            <Checkbox
              checked={isNextMunjeChecked}
              onChange={handleCheckboxChange}
              id="chkNextMoonje"
              color="primary"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 38, marginTop: -0.5 } }}
            />
          }
          
          label={
            <span style={{ fontSize: "1rem", marginLeft: "2rem"}}>다음 문제로 자동 이동</span>
          } // 폰트 크기를 크게 조정
        />



        <div className="parent">
          <Button variant="contained" color="primary"  
          className="setting_right_chk_next_setting_save" onClick={handleSave} >저장</Button>
        </div>

      </div>
    </div>
  </div>
  );
}

export default MarkingSetting;
