import './draw.css';
import React, { useEffect, useState, useRef, useCallback  } from 'react';


const DrawMenu = ({ 
  activeButton, 
  handleButtonPress, 
  handleEraseClick, 
  undo, 
  redo, 
  clear, 
  zoomIn, zoomOut,
  setIsShowList,
 }) => {
  
    useEffect(() => {
        console.log('DrawMenu Active Button:', activeButton);
      }, [activeButton]);
    
  
  
    return (
    <div className='box-menu'>
 
        <input  type="button" 
          //className={'btnList'} value={''} onClick={() => setIsShowList(true)} 
          className={'btnList'} value={''} onClick={() => handleButtonPress("list", "save")} //setIsShowList(사용안함) 
        />

        <input  type="button" 
          className={`btnColor btnBlack ${activeButton === 'btnBlack' ? 'btnActive' : ''}`}   
          onClick={() => handleButtonPress("black", "btnBlack")} 
        />
        
        <input type="button" 
          className={`btnColor btnRed ${activeButton === 'btnRed' ? 'btnActive' : ''}`}  
          onClick={() => handleButtonPress("red", "btnRed")} 
        />

        <input 
          className={`btnColor btnBlue ${activeButton === 'btnBlue' ? 'btnActive' : ''}`}
          type="button" 
          onClick={() => handleButtonPress("blue", "btnBlue")} 
        />

      <input 
          className={`btnColor btnGreen ${activeButton === 'btnGreen' ? 'btnActive' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("green", "btnGreen"); }} 
        />

        <input 
          className={`btnColor btnYellow ${activeButton === 'btnYellow' ? 'btnYellow-active' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("yellow", "btnYellow"); }} 
        />

       
         <input 
          className={`btnColor btnGreenyellow ${activeButton === 'Greenyellow' ? 'btnGreenyellow-active' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("greenyellow", "Greenyellow"); }} 
        />
         <input type="button" 
          className={`btnEraser ${activeButton === 'eraser-active' ? 'btnEraser-active' : 'btnEraser'}`}  
          onClick={() => handleEraseClick()} 
        />
          <input type="button" className='btnUndo' onClick={undo}/>
          <input type="button" className='btnRedo' onClick={redo}/>
          <input type="button" className='btnClear' onClick={clear}/>
          <input type="button" className='btnZoomIn' onClick={zoomIn} value="Zoom In"/>
          <input type="button" className='btnZoomOut' onClick={zoomOut} value="Zoom Out"/>
       
      </div>
  );
}

export default DrawMenu;
