import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

function setCookie(key, value, day) {
  let secretKey = process.env.REACT_APP_secretKey; // 이 키는 절대 노출되어서는 안됩니다!
  const encryptedVaule = CryptoJS.AES.encrypt(value,secretKey).toString();
  Cookies.set(key, encryptedVaule, {expires: day});
}

function getCookie(key) {
    let secretKey = process.env.REACT_APP_secretKey; 
    const encryptedValue = Cookies.get(key);
    if (!encryptedValue)  return '';
    const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const value = bytesValue.toString(CryptoJS.enc.Utf8);
    return value;
}

function getCookie_pure(key) {
  const value = Cookies.get(key);
  return value;
}

function fncDecrypt(encryptedValue) {
  let secretKey = process.env.REACT_APP_secretKey; 
  
  if (!encryptedValue)  return '';
  const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  const value = bytesValue.toString(CryptoJS.enc.Utf8);
  return value;
}

function fncRound(value, total, digit) {
  let percentage = (value / total) * 100;
  return Math.round(percentage * Math.pow(10, digit)) / Math.pow(10, digit);
}

function updateListItem(list, rowIndex, fieldName, newValue) {
  // 불변성을 유지하기 위해 배열을 복사합니다.
  const newList = [...list];
  // 주어진 인덱스가 배열 범위 내에 있는지 확인하고, 해당 요소를 업데이트합니다.
  if (rowIndex >= 0 && rowIndex < newList.length) {
      newList[rowIndex] = { ...newList[rowIndex], [fieldName]: newValue };
  }

  return newList;
}


const fnc = { setCookie, getCookie, fncDecrypt, getCookie_pure, fncRound, updateListItem }
export default fnc;
// module.exports = fnc;

