import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { createEraserCircle, updateEraserCircle, removeEraserCircle } from './drawFnc';
import './drawSwip.css';
// import { useGesture } from 'react-use-gesture';
import { useGesture } from '@use-gesture/react';

const DRAW_MODE = 'draw';
const ERASE_MODE = 'erase';
let scaleLet = 1;

const getMidPoint = (point1, point2) => ({
  x: (point1.x + point2.x) / 2,
  y: (point1.y + point2.y) / 2,
});



const DrawSwip = forwardRef(
  (
    {
      imgUrlQue,
      allowTouchMove,
      setAllowTouchMove,
      activeButton,
      setActiveButton,
      currentColor,
      mode,
      highlightMode,
      index,
      scaleRefs,
    },
    ref
  ) => {
    const svgRefs = useRef([]);
    const svg = svgRefs.current[index];
    const points = useRef([]);
    const colorRef = useRef("black");
    const highlightModeRef = useRef(false);
    const drawnPaths = useRef([]); // 그려진 경로를 저장할 배열
    const removedPaths = useRef([]); // 지워진 경로를 저장하기 위한 배열
    const [viewBox, setViewBox] = useState({
      x: 0,
      y: 0,
      width: 800,
      height: 400,
    });

    const [imgWidth, setImgWidth] = useState(600);
    const [viewBoxHeight, setViewBoxHeight] = useState(1500);
    const [scale, setScale] = useState(1);
    const scaleRef = useRef(1);
    const [device, setDevice] = useState('');
    console.log("2. -----------------------drawSwip.js");
        
    let currentPath;
           
    const deletePath = (x, y) => {
      const svg = svgRefs.current[index];
      const paths = svg.getElementsByTagName("path");

      for (const path of paths) {
        const pathData = path.getAttribute("d");
        const pathDataArray = pathData.split(" ");

        for (let i = 1; i < pathDataArray.length - 1; i++) {
          const lineData = pathDataArray[i].split(",");
          const lineX = parseFloat(lineData[0]);
          const lineY = parseFloat(lineData[1]);

          if (Math.abs(lineX - x) < 15 && Math.abs(lineY - y) < 15) {
            removedPaths.current.push(path); // 지운 path를 removedPaths 배열에 추가
            svg.removeChild(path);
            return; // Return here if you want to remove only one path
          }
        }
      }
    };


    const handleDrawing = (clientX, clientY, type) => {
      const svg = svgRefs.current[index];
      const pt = svg.createSVGPoint();
    
      pt.x = clientX;
      pt.y = clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());

      // if (device === 'Android') {
      //   scaleLet = 1; 
      // }else {
      //   scaleLet = scaleRef.current.textContent; 
      // }
     
      scaleLet = 1; 
      const { x: x2, y: y2 } = svgP;
      const x = x2 / scaleLet;
      const y = y2 / scaleLet;

      if (mode === ERASE_MODE) {
        updateEraserCircle(x, y); // 원 위치 업데이트
        deletePath(x, y);
        if (type === "start") {
          createEraserCircle(x, y, svg);
        }
        return;
      }

      if (type === "start") {

        points.current = [{ x, y }];
  
        currentPath = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        let strokeWidth = 2;
        let strokeOpacity = 1;
        if (highlightModeRef.current) {
          strokeWidth = 15;
          strokeOpacity = 0.5;
        }
        currentPath.setAttribute("stroke-width", strokeWidth);
        currentPath.setAttribute("stroke-opacity", strokeOpacity);
        currentPath.setAttribute("stroke", colorRef.current);
        currentPath.setAttribute("fill", "none");
        currentPath.setAttribute("d", `M ${x},${y}`);
        currentPath.setAttribute("stroke-linecap", "round"); // 선의 끝이 둥글게
        // currentPath.setAttribute("stroke-linecap", "square"); // 선의 끝이 넘어가

        // 블렌드 모드 적용
        if (
          colorRef.current === "yellow" ||
          colorRef.current === "greenyellow"
        ) {
          currentPath.style.mixBlendMode = "multiply";
        }

        svg.appendChild(currentPath);
        drawnPaths.current.push(currentPath); // 그려진 경로를 drawnPaths 배열에 저장

      } else if (type === "move") {
         points.current.push({ x, y });
        if (points.current.length < 3) {
          return;
        }

        const [point1, point2, point3] = points.current.slice(-3);
        const midPoint1Raw = getMidPoint(point1, point2);
        const midPoint2Raw = getMidPoint(point2, point3);
        
        const midPoint1 = {
          x: midPoint1Raw.x,
          y: midPoint1Raw.y,
        };

        const midPoint2 = {
          x: midPoint2Raw.x,
          y: midPoint2Raw.y,
        };

        const d = currentPath.getAttribute("d") || ""; // 'd' 속성이 null일 경우 대비
        const curve = ` C ${midPoint1.x},${midPoint1.y} ${point2.x},${point2.y} ${midPoint2.x},${midPoint2.y}`;
        currentPath.setAttribute("d", `${d} ${curve}`);

      }
    };

    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // setDevice(userAgent);
      if (/android/i.test(userAgent)) {
        setDevice('Android');
      } else if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
        setDevice('iPad');
      } else {
        setDevice('Unknown');
      }
    }, []);

    useEffect(() => {
      colorRef.current = currentColor;
      highlightModeRef.current = highlightMode;
    }, [currentColor]);

    useEffect(() => {
      const svg = svgRefs.current[index];
      const touchStart = (e) => {
        if (
          !e.touches ||
          e.touches.length === 0 ||
          e.touches[0].radiusX !== 0
        ) {
          //손가락
          // setAllowTouchMove(true);
          if (e.touches.length === 1) {
            setAllowTouchMove(true);
          }else {
            setAllowTouchMove(false);
          }

          return; // Ignore if it's not a pen
        }

        if (e.touches[0].radiusX === 0) {
          //펜일 때  
            setAllowTouchMove(false);
         
          e.preventDefault();
          const { clientX, clientY } = e.touches[0];
          handleDrawing(clientX, clientY, "start");
        }
      };

      const touchMove = (e) => {
        if (
          !e.touches ||
          e.touches.length === 0 ||
          e.touches[0].radiusX !== 0
        ) {
          return; // Ignore if it's not a pen
        }

        if (e.touches[0].radiusX === 0) {
          e.preventDefault();
          const { clientX, clientY } = e.touches[0];
          handleDrawing(clientX, clientY, "move");
        }
      };

      const touchEnd = () => {
        points.current = [];
        const svg = svgRefs.current[index];
        removeEraserCircle(svg); // 원 제거
      };

      svg.addEventListener("touchstart", touchStart);
      svg.addEventListener("touchmove", touchMove);
      svg.addEventListener("touchend", touchEnd);

      return () => {
        svg.removeEventListener("touchstart", touchStart);
        svg.removeEventListener("touchmove", touchMove);
        svg.removeEventListener("touchend", touchEnd);
      };

    }, [mode]);

    //-------------------------------------------------------------------------------useImperativeHandle
    useImperativeHandle(ref, () => ({
      undo: () => {
        const svg = svgRefs.current[index];
        const paths = svg.querySelectorAll("path");
        if (paths.length > 0) {
          const latestPath = paths[paths.length - 1];
          removedPaths.current.push(latestPath);
          svg.removeChild(latestPath);
        }
      },
      redo: () => {
        const svg = svgRefs.current[index];
        if (!svg) return; // svg가 없다면 함수를 빠져나옵니다.
        if (removedPaths.current.length > 0) {
          const latestRemovedPath = removedPaths.current.pop();
          svg.appendChild(latestRemovedPath);
        }
      },
      clear: () => {
        const rtn = window.confirm(
          "메모 전체를 지우시겠습니까? \n(배경 내용은 유지됩니다.)"
        );
        if (!rtn) return;
        const svg = svgRefs.current[index];
        if (!svg) return;
        const paths = svg.getElementsByTagName("path");
        while (paths.length > 0) {
          svg.removeChild(paths[0]);
        }
      },

  zoomIn: () => {
    const currentWidth = svgRefs.current[index].clientWidth * scale;
    if (currentWidth < window.innerWidth) {
      const newScale = Math.min(scale + 0.1, 3);
      setScale(newScale);
 
    }
  },

  zoomOut: () => {
    const newScale = Math.max(scale - 0.1, 0.5); 
    setScale(newScale);
  },
  }));

  const handleClick = () => {
    ////return;//지우지 말 것 마우스로 테스트 용
    // if (scale === maxScale) {
    //   setScale(1); // 만약 현재 스케일이 maxScale이라면 scale을 1로 설정합니다.
    //   console.log( 1);
    // } else {
    //   setScale(maxScale); // 그렇지 않으면 maxScale로 설정합니다.
    // }
    // handleDrawing(0, 0, "start");
  };

  
      useEffect(() => {
      if (!imgUrlQue || !imgUrlQue.imgPathQue) return; // imgUrlQue 또는 imgUrlQue.imgUrlQue가 없다면 리턴
      const svg = svgRefs.current[index];
      const imgUrlPath = imgUrlQue.imgPathQue;

      console.log(imgUrlPath, 'imgUrlPath===========================');
      const img = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      img.setAttributeNS(null, "x", "0");
      img.setAttributeNS(null, "y", "20");//이미지 top
      img.setAttributeNS(null, "width", `${imgWidth}`);

      img.setAttributeNS(
        "http://www.w3.org/1999/xlink",
        "xlink:href",
        imgUrlPath
      );
      svg.appendChild(img);
      
    }, [imgWidth]);

  
    //#######################################################################################

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const maxScale = vw / imgWidth;

    const bind = useGesture({
      onPinch: ({ offset: [d, a] }) => {
        const clampedScale = Math.max(1, Math.min(d, maxScale)); // 여기서 clamp를 사용합니다.
        setScale(clampedScale);
      },
      onDoubleClick: () => {
        if (scale === maxScale) {
          setScale(1); // 만약 현재 스케일이 maxScale이라면 scale을 1로 설정합니다.
        } else {
          setScale(maxScale); // 그렇지 않으면 maxScale로 설정합니다.
        }
      },
      // threshold: 1,//이거 안먹혀?
    });

  
 
    return (
      <div className="draw">
        <div className="box-svg">
          <svg
            {...bind()}
            onClick={handleClick}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%) scale(' + scale + ')',
              transformOrigin: '50% 0',
              width: imgWidth,
              height: 1500,
            }}
            ref={(el) => {
              svgRefs.current[index] = el; 
            }      
          }
            viewBox={`0 0 ${imgWidth} ${viewBoxHeight}`}
          ></svg>
        </div>

      {/* <div className='scale' ref={scaleRef}>{scale}</div>;
      <p  d-none>Your Device is: {device}</p> */}

      </div>
    );
  }
);

export default React.memo(DrawSwip);

