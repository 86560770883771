import "./markingTableOne2.scss"; //css는 Top에 와야..
import React, { useEffect, useState, memo} from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {  setSelectedRow, setIsShowList, setSelectedRowOld, fncResetSlide} from "../reduxToolkit/redux";

const MarkingTableOne2 = (({ 
  list2,
  isLoadImgComplete, 
 }) => {

  
const dispatch = useDispatch();
const isShowList = useSelector(state => state.reduxStore.isShowList);
const selectedRow = useSelector(state => state.reduxStore.selectedRow);

function onMarkRowClick(Row) {

    if (!isLoadImgComplete) return;//이거 중요해 없으면 중간에 여러번 중복돼서 실행되는 듯 (2주 개고생)
    dispatch(fncResetSlide());
    dispatch(setSelectedRowOld(selectedRow));
    dispatch(setSelectedRow(Row));
    dispatch(setIsShowList(false));
}

//========================================================================= return
return (
  <div>
    {isShowList && (
      <div className="MarkingTableOne2">
        <div className="wrap-list-markingSt">
          <table>
            <thead>
              <tr>
                <th style={{ width: "5rem" }}>번호</th>
                <th style={{ width: '0', padding: '0', borderRight: 'none'}}></th>
                <th style={{ width: "4.5rem", borderLeft: 'none', paddingRight:'13px' }}>OX </th>
                <th style={{ width: "5rem" }}>정답률</th>
                <th style={{ width: "5rem" }}>횟수</th>
                <th style={{ width: "5rem" }}>중요</th>
                <th style={{ width: "5rem" }}>이유</th>
                <th style={{ width: "5rem" }}>경과일</th>
              </tr>
            </thead>
            <tbody>
              {list2 && list2.map((item, index) => (

                <tr
                  className={`row-list ${selectedRow === item.no ? "selected activeRow" : ""}`}
                  key={item.no}
                  onClick={() => {onMarkRowClick(item.no)}} >
                  
                  <td>{item.no}</td>
                  <td style={{ width: '10px', padding: '0', overflow: 'hidden', borderRight: 'none' }}>
                    {item.marking || ""}</td>
                  <td style={{textAlign: 'center', paddingLeft: '0rem', borderLeft: 'none'}}>
                    {item.OX || ""}</td>
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.정답율 || ""}</td>
                  <td style={{ textAlign: 'center', paddingLeft: '1rem' }}>
                            {item.cnt === 0 ? '' : `${item.cntO}/${item.cnt}`}
                    </td>
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.중요 || ""}</td>
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.기록 || ""}</td>
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.경과일 || ""}</td>

                </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="box_footer">
            <button className="close_table" onClick={()=> dispatch(setIsShowList(false))}>닫기</button>
        </div>


      </div>
    )}
  </div>
);
});

export default MarkingTableOne2;