import  './showDap.css';
import React, {useState} from "react";
import imgQuestion_markO from "../images/png/question_markO.png"; 
import imgQuestion_markX from "../images/png/question_markX.png"; 

const ShowDap = ({
    info,
    setIsShowOX,
}) => {

    return (
        <div className='ShowDap'>
            <div className="box_dap">
                {/* <label className='OX'>{info.OX}</label> */}

                <img
                className={`img_dap ${info.OX === 'O' ? 'bg-info_dap' : 'bg-danger_dap'}`}
                src={info.OX === 'O' ? imgQuestion_markO : imgQuestion_markX}
                alt={info.OX === 'O' ? 'Correct' : 'Incorrect'}
            />

                <label>[정답] {info.dap}</label>
                <label>[마킹] {info.markingValue}</label>
                <input type='button' className='btnCloseOX' onClick={() => {setIsShowOX(false);}} value={'닫기'} />
            </div>
        </div>
    );
};

export default ShowDap;