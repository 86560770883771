import "./markingOneApp2.scss"; 
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import MarkingTable from "./MarkingTableOne2";
import MemoCanvasApp2 from "../drawCanvas/MemoCanvasApp2";
import MarkingMenuSpecial from "../comm/MarkingMenuSpecial";

import { useSelector, useDispatch } from 'react-redux';
import {setMarkingList_init, setMarkingList, addInfo, updateList2, setIsShowList, setIsCommBoxVisible, 
        setKind, setList, setIsActiveQueSolDap, setIsShowOX, initializeQue } from "../reduxToolkit/redux";

import fnc from "../mymod/commFunction";
import DisplayOX from "../comm/DisplayOX";
import ShowDap from "../comm/ShowDap";


const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingOneApp2() {
  const navigate = useNavigate(); 
  const location = useLocation();
  const testNm = location.state?.testNm;
  const munCnt= location.state?.munCnt;
 
  const stCode = fnc.getCookie('stCode');
  const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;

  const [no, setNo] = useState("1");
  const [inputValue, setInputValue] = useState(''); 
  const [isShowSpecial, setIsShowSpecial] = useState(false);
  
  const [markingNum, setMarkingNum]= useState();
  const [chejumCompleted, setChejumCompleted]= useState(false);
  const [isLoadImgComplete, setIsLoadImgComplete]= useState(true);
  
  //-----------------------------------------------------------------
  
  const markingList = useSelector(state => state.reduxStore.markingList);
  const isShowList = useSelector(state => state.reduxStore.isShowList);
  const list = useSelector(state => state.reduxStore.list);
  const selectedRow = useSelector(state => state.reduxStore.selectedRow);
  const isActiveQueSolDap = useSelector(state => state.reduxStore.isActiveQueSolDap);
  const isShowOX = useSelector(state => state.reduxStore.isShowOX);

  const [OX, setOX] = useState();//이걸로 동기화
  const dispatch = useDispatch();

  //--------------------------------------------------------맨 위 정답률 바

  const [importValue, setImportValue] = useState(null);
  const [mistakeValue, setMistakeValue] = useState(null);
  const [conceptLackValue, setConceptLackValue] = useState(null);
  const [info, setInfo] = useState({OX: null, dap: null, markingValue: null});
  const [list2, setList2] = useState([]);
  const [selectedList, setSelectedList] = useState({});


  useEffect(() => {
     const initialState = {};
     for (let i = 1; i <= munCnt; i++) {
        initialState[i] = '';
     }
        dispatch(setMarkingList_init(initialState)); 
        fncSelectListOne(testCodeMun, testCode, stCode);
        dispatch(initializeQue()); 
  }, []);


const fncSelectListOne = async (testCodeMun, testCode, stCode) => {
  try {
    const response = await axios.post(`${svrUrl}/markingStListOne`, { testCodeMun, testCode, stCode });
    const jsonObj = response.data;
        dispatch(setList(jsonObj));
        setList2(jsonObj);
        // 초기화 MarkingApp2.js의 응시버튼 쪽으로 옮김
        // setIsShowList(false);
        // dispatch(setSelectedRowOld(0));
        // dispatch(setSelectedRow(1));
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};



  useEffect(() => {
    dispatch(addInfo({ key: 'munCnt', value: list.length }));//이건 필요 함
  }, [list]);


  useEffect(() => {
    setNo(selectedRow);
    if (list2.length > 0 && selectedRow > 0) {
          setOX(list2[selectedRow - 1].OX);
          setImportValue(list2[selectedRow - 1].중요);
          const mistake = (list2[selectedRow - 1].기록 === '실수') ? '실수' : '';
          const concepLake = (list2[selectedRow - 1].기록 === '개념') ? '개념' : '';
          setMistakeValue(mistake);
          setConceptLackValue(concepLake);
          // fncKindQue(); //selectedRow가 바뀔 때 문제로 초기화 이거 찾느라 고생
          // 첫 문제 띄우는 건 canvasApp2에서 selectedRow가 변경되는 순간 뜬다. 이건 필요 없는 듯
          setMarkingNum('');//12345? 버튼 초기화      
          setSelectedList(list[selectedRow-1]);    
    
    }
  }, [list2, selectedRow]);//list2가 있어야 처음 로딩 시 정답률 등이 나온다. selectedRow는 기본 1이라 안 바뀜


const toggleSpecial = () => {
  setIsShowSpecial(!isShowSpecial);
};

const fncNoBgColorClassName = (OX) => {
  if (OX === 'O') return 'bg-green';
  if (OX === 'X') return 'bg-red';
  return 'bg-gray';
};


const fncOnMarking = (no, value) => {
  dispatch(setMarkingList({ no, value }));
  setMarkingNum(value); //1,2,3,4,5 버튼 bgColor
};


function fncTestClose() {
  const confirmed = window.confirm('학습을 종료하시겠습니까?');
  if (!confirmed) return
  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)
  navigate("/markinglistone2");
}



function fncOnMarkingImport() {  
  const kind = 'important';
  const value = !importValue ? '중요' : '';   
  fncCheckImportant(kind, value);
}

function fncOnMarkingMistake() {  
const kind = 'mistake';
const value = !mistakeValue ? '실수' : '';
fncCheckImportant(kind, value);
}

function fncOnMarkingConceptLake() {  
const kind = 'concept';
const value = !conceptLackValue ? '개념' : '';
fncCheckImportant(kind, value);
}

//--------------------------------------------------------------------------/중요/실수/개념

function fncCheckImportant(kind, value) {//한 개로 통합(import, mistake, conceptLake)
  const currentDate = new Date();
  const studyDay = currentDate.toISOString().split('T')[0];// 날짜를 YYYY-MM-DD 형식으로 변환합니다.
  const munCode= list[selectedRow-1].문항번호;       

  fncUpdateImportant(stCode, studyDay, munCode, value, kind, testCode )
}


function fncUpdateImportant(stCode, studyDay, munCode, markingValue, kind, testCode ) {
  sendAxios("/updateImportant", {stCode, studyDay, munCode, markingValue, kind, testCode  }, (response) => {

  if (response) {
    const result = response.result;
    if (result === 'Y') {
      if(kind ==='important') {//important import 이거 때문에 고생 함/ *updateList2로 해야 테이블 새로고침 안 됨
        // dispatch(updateList2({ Row: selectedRow, fieldName: '중요', newValue : markingValue }));
        const updatedList = fnc.updateListItem(list2, selectedRow -1, '중요', markingValue);
        setList2(updatedList); // 업데이트된 리스트로 상태 갱신

        setImportValue(markingValue);
      }else if (kind ==='mistake') {
        // dispatch(updateList2({ Row: selectedRow, fieldName: '기록', newValue : markingValue }));
        const updatedList = fnc.updateListItem(list2, selectedRow -1, '기록', markingValue);
        setList2(updatedList); // 업데이트된 리스트로 상태 갱신

        setMistakeValue(markingValue);
        setConceptLackValue('');
      }else if (kind === 'concept') {
        // dispatch(updateList2({ Row: selectedRow, fieldName: '기록', newValue : markingValue }));
        const updatedList = fnc.updateListItem(list2, selectedRow -1, '기록', markingValue);
        setList2(updatedList); // 업데이트된 리스트로 상태 갱신

        setConceptLackValue(markingValue);
        setMistakeValue('');
      }
      
    }    
  } else {
    console.log("속성이 obj 내에 없습니다.");
  }
});
}


function fncKindComm() {//공통지문으로 변경(사용 안 해)
  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)
  dispatch(setIsActiveQueSolDap('comm'));
  fncSelectQueSol('comm');
  dispatch(setIsShowOX(false));
  dispatch(setKind('comm'));

}

function fncKindQue() {//문제로 변경
  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)
  dispatch(setIsActiveQueSolDap('que'));//슬라이드 시 이걸로 초기화 함
  setIsActiveQueSolDap('que');
  fncSelectQueSol('que');
  dispatch(setIsShowOX(false));
  dispatch(setIsCommBoxVisible(false));
  dispatch(setKind('que'));

}

function fncKindSol() {
  setIsLoadImgComplete(false);//table에서 selectRow가 바뀌면서 랜더링 일어나는데 (이게 영향이 있는지 모르겠다.)
  dispatch(setIsActiveQueSolDap('sol'));
  fncSelectQueSol('sol');
  dispatch(setIsShowOX(false));
  dispatch(setIsCommBoxVisible(false));
  dispatch(setKind('sol'));
}


const fncSelectQueSol = (kindValue) => {
  setKind(kindValue);//이걸 안해서 문풀 버튼 클릭 시 꼬였다 (고생 함)
  dispatch(setIsShowList(false));
}


function fncKindDap() {
  dispatch(setIsActiveQueSolDap('dap'));
  setInfo({OX: list2[selectedRow - 1]['OX'], dap: list2[selectedRow - 1]['정답'], markingValue: list2[selectedRow - 1]['마킹']})//markingbar로 안 함
  dispatch(setIsShowOX(!isShowOX));
}



//채점--------------------------------------------------------------------------/
function fncChejum() {
 
  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];// 날짜를 YYYY-MM-DD 형식으로 변환합니다.
  const studyDay = today;
  const munCode= selectedList.문항번호;
  const testCode= selectedList.시험지코드;
  const no = selectedList.no;
  const dap = selectedList.정답;
  const cntO = selectedList.cntO;
  const cntX = selectedList.cntX;
  const cnt = selectedList.cnt;

  let markingValue = markingList[selectedRow];  

  if(!markingValue) {
    alert('마킹을 하세요!');
    return null;
  }

  const OX = (dap === markingValue) ? 'O': 'X';
  const 정답율 = fnc정답율(OX, cntO, cntX, cnt); 

  const updatedCntO = OX === 'O' ? cntO + 1 : cntO;
  const updatedTotal = cnt + 1;

  fncInsert_te_markingOne(studyDay, munCode, no, dap, OX, markingValue, testCode,정답율,updatedCntO, updatedTotal);

}

  function fnc정답율(OX, cntO, cntX, total) {
    const updatedCntO = OX === 'O' ? cntO + 1 : cntO;
    const updatedCntX = OX === 'X' ? cntX + 1 : cntX;
    const updatedTotal = total + 1;
    const 정답율 = Math.round((updatedCntO / updatedTotal) * 100 * 100) / 100
    return 정답율;
  }
  
  function fncInsert_te_markingOne(studyDay, munCode, no, dap, OX, markingValue, testCode,정답율,updatedCntO, updatedTotal) {
      sendAxios("/insertMarkingOne", {stCode, studyDay, munCode, no, OX, markingValue, testCode  }, (response) => {
      if (response) {

        let result = response.result;
        if (result === 'Y') {

            setOX(OX);
            setChejumCompleted(true);//list2로 table만 별도로 상태관리해서 새로고침 현상 해결

            dispatch(updateList2({Row: selectedRow, fieldName: '마킹', newValue: markingValue})); 
            dispatch(updateList2({Row: selectedRow, fieldName: 'OX', newValue: OX})); 
            dispatch(updateList2({Row: selectedRow, fieldName: '정답율', newValue: 정답율 + '%'})); 
            dispatch(updateList2({Row: selectedRow, fieldName: '경과일', newValue: '오늘'})); 
            dispatch(updateList2({Row: selectedRow, fieldName: 'cntO', newValue: updatedCntO})); 
            dispatch(updateList2({Row: selectedRow, fieldName: 'cnt', newValue: updatedTotal})); 
 
            
        } else {
          alert('실패했습니다.');
        }   
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }

  
const sendAxios = async (url, data, callback) => {//이건 하나만 있으면 됨

  try {
    const response = await axios.post(`${svrUrl}${url}`, data);
    let jsonObj = response.data;
    callback(jsonObj);
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};



//========================================================================================

  return (
    <div className="MarkingOneApp2">

      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={`${testNm} ( ${munCnt}문항 ) ` } />
      </div>

      <div className="box-titleBar">         
             <div className="box_noLeft">

                {selectedList.정답율 && <span className="lbl_marking_info_memo correctRate ">{`정답률 ${list2[selectedRow-1].정답율}`}</span> }
                {!!selectedList.cnt && <span className="lbl_marking_info_memo cnt">
                      {list2[selectedRow-1].cnt > 0 ? `횟수 ${list2[selectedRow-1].cntO}/${list2[selectedRow-1].cnt}` : ''}
                  </span>}

                  {selectedList.경과일 && <span className="lbl_marking_info_memo day">{`경과일 ${list2[selectedRow-1].경과일}`}</span> }
             </div>  
   
   
             {true && (<div className="box-important">
                      
                     {true && <input type="button" className={`btnWrongType_important ${importValue ? "btnActive_important" : ""} `}  onClick={fncOnMarkingImport} value='중요'/>}
                     {true && <input type="button" className={`btnWrongType_mistake ${mistakeValue ? "btnActive_mistake" : ""} `} onClick={fncOnMarkingMistake} value='실수'/>}
                     {true && <input type="button" className={`btnWrongType_concept ${conceptLackValue ? "btnActive_concept" : ""} `} onClick={fncOnMarkingConceptLake} value='개념'/>}
   
                     {/* {true &&  ( <input type="button" className={`btnque ${isActiveQueSolDap === 'que' ? 'btnActive_qm' : ''}`} value="지문" onClick={fncKindComm} />)} */}
                     {true &&  ( <input type="button" className={`btnque ${isActiveQueSolDap === 'que' ? 'btnActive_qm' : ''}`} value="문제" onClick={fncKindQue} />)}
                     {true &&  ( <input type="button" className={`btnsol ${isActiveQueSolDap === 'sol' ? 'btnActive_qm' : ''}`} value="풀이" onClick={fncKindSol} />)}
                     {true && <input type="button" className={`btndap ${isActiveQueSolDap === 'dap' ? 'btnActive_qm' : ''}`} value="정답" 
                           onClick={() => { fncKindDap();}} />} 
                      
                 </div>       
             )}
   
   
         </div>

      <div className="box-header-memo2">
          <span className={`lblNo ${fncNoBgColorClassName(OX)}`}>{no}번</span>
          {true && <span className="lbl_markingNum">{markingList[no]}</span>}

          {(<div className="box-btnNumer">
              
            {['1', '2', '3', '4', '5', '?'].map((num) => (
                <button
                  key={num}
                  className={`btn_number ${markingNum === num ? 'btnSelected' : ''}`}
                  onClick={() => {fncOnMarking(no, num);}} > {num} </button>
            ))}
            

              <input type="button" className="btnSpecial" onClick={toggleSpecial} value='주관식'  />
              <button className="btnChjum" onClick={ fncChejum }>채점</button>
              
          </div>
          )}

        
      </div>

      { !isShowList && list.length > 0 && <MemoCanvasApp2 
                height={`calc(100vh - 11.5rem)`}  
                isLoadImgComplete={isLoadImgComplete} 
                setIsLoadImgComplete={setIsLoadImgComplete}                    
            />  }
            

      { isShowList && <MarkingTable
              list2={list2} list={list}
              isLoadImgComplete={isLoadImgComplete} 
              setIsLoadImgComplete={setIsLoadImgComplete}
       /> }

      { isShowSpecial && (<MarkingMenuSpecial 
                setInputValue={setInputValue} 
                isShowSpecial={isShowSpecial} 
                setIsShowSpecial={setIsShowSpecial}
                selectedRow={selectedRow} 
            />)}

      { chejumCompleted && <DisplayOX  
                OX={OX} 
                setChejumCompleted={setChejumCompleted}
         /> }

      { isShowOX && <ShowDap info={info} />}

      </div>
  );
}

export default MarkingOneApp2;
