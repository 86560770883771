import React, {useEffect} from 'react';
import './displayOX.css';
import imgQuestion_markO from "../images/png/question_markO.png"; 
import imgQuestion_markX from "../images/png/question_markX.png"; 

const DisplayOX = ({ OX, setChejumCompleted }) => { // props를 객체 구조 분해 할당을 사용하여 받음

    useEffect(() => {
        // Set a timeout to change the state after 3 seconds
        const timer = setTimeout(() => {
          setChejumCompleted(false);
        }, 2000); // 3000 milliseconds = 3 seconds
    
        // Clean up the timer when the component unmounts to avoid memory leaks
        return () => clearTimeout(timer);
      }, [setChejumCompleted]); // The effect depends on setChejumCompleted function

      
  return (
    <div className={`DisplayOX ${OX === 'O' ? 'bg-info' : 'bg-danger'}`}>
            <img
                className={`img ${OX === 'O' ? 'bg-info' : 'bg-danger'}`}
                src={OX === 'O' ? imgQuestion_markO : imgQuestion_markX}
                alt={OX === 'O' ? 'Correct' : 'Incorrect'}
            />
            <div className="img_text">{OX === 'O' ? '맞았습니다.' : '틀렸습니다.'}</div>
    </div>
  );
}

export default DisplayOX;
