import styles from "./markingMenuOne.css"; 
import axios from "axios";
// import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import MarkingMenuSpecial from "../marking/MarkingMenuSpecial";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { useRef, forwardRef, useImperativeHandle } from 'react';
import fnc from '../mymod/commFunction';
// import { Alert } from "bootstrap";


const MarkingMenuOne = forwardRef(({
  selectedRow,
  setSelectedRow, 
  setNo, 
  setInfo, 
  isShowList, 
  setIsShowList, 
  list, setList,
  imgSize, 
  setImgSize, 
  markingList, 
  setMarkingList,
  showModal, 
  setShowModal,
  setImportValue,
  setMistakeValue,
  setConceptLackValue,
  setIsMarkinged,
  setIsShowOX,
  onFncNoBgColorClassName,
  OX, setOX,
  markingNum, setMarkingNum,
  chejumCompleted, setChejumCompleted,
  imgViewState,
  isActiveQue, setIsActiveQue,
}, ref) => {

  const location = useLocation();
  const testCode = location.state?.testCode;
  const munCnt= location.state?.munCnt;

  const [inputValue, setInputValue] = useState(''); 
  const [isShowSpecial, setIsShowSpecial] = useState(false);

  const navigate = useNavigate();
  const svrUrl = process.env.REACT_APP_SERVER_URL;

  const acaCode = fnc.getCookie('acaCode');
  const id = fnc.getCookie('id');
  const stCode = fnc.getCookie('stCode');

  console.log("4----------------------------------------------MarkingMenu");
  console.log(imgViewState, 'imgViewStateimgViewStateimgViewStateimgViewState');
  console.log(isActiveQue, 'isActiveQueisActiveQueisActiveQue');

  useEffect(() => {
    const savedSize = Cookies.get('imgSize');
    if (savedSize) {
      setImgSize(Number(savedSize));
    }
  }, []);




  function fncTestClose() {
    const confirmed = window.confirm('시험을 종료하시겠습니까?');
    if (confirmed) {
      navigate("/markinglist");
    }
  }

  function fncShowList() {
    setIsShowList(!isShowList);
  }

  function fncCloseList() {
    setIsShowList(false);
  }

  const fncZoomOut = () => {
    if (imgSize < 200) {
      alert('최소크기 입니다!');
      return;
    }
    const newSize = imgSize * 0.9;
    setImgSize(newSize);
    Cookies.set('imgSize', newSize, { expires: 1000 });
  };

  
  const fncZoomIn = () => {
    let newSize = imgSize < 200 ? 200 : imgSize;
    newSize = Math.round(imgSize * 1.1 + 0.5);  // 반올림
    setImgSize(newSize);
    Cookies.set('imgSize', newSize, { expires: 1000 });
  };

   function fncPrev() {
      if (list && selectedRow !== null) {
        const currentIndex = selectedRow - 1;  // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
    
        if (currentIndex > 0) {
          const nextItem = list[currentIndex - 1];
          fncSelectRow(nextItem);
          // setShowModal(true);
        } else {
          alert("처음입니다.");
        }
      }
    }

    function fncNext() {
      if (list && selectedRow !== null) {
        const nextIndex = selectedRow; // item.no가 인덱스 + 1 이므로
        if (nextIndex < list.length) {
          const nextItem = list[nextIndex];
          fncSelectRow(nextItem);
          // setShowModal(true);
        } else {
          alert('마지막입니다!');
        }
      }
    }

   
   


    function fncInsertMarkingValue() {
      fncHandleMarking(selectedRow, inputValue);
      setMarkingNum(inputValue);
      // setInputValue('');  // 입력 완료 후 input 값을 초기화
    }
  
    function fncInputValueChange(e) {
      setInputValue(e.target.value);
      setMarkingNum(e.target.value);
    }
    
    
  function fncSelectRow(item) {//3군데 바꿔야 함(Table, Menue, App)
    if (!item) return false;
    setSelectedRow(item.no);
    setNo(item.no + '번');
    setImportValue(item.중요);

    const OX = item.OX;
    const 정답율 = item.정답율;
    const 경과일 = item.경과일;
    const dap = item.정답;
    const markingValue = item.마킹;
    setInfo({ OX: OX, incorrectRate: 정답율, elapsedDays: 경과일, dap: dap, markingValue: markingValue });

    const mistake = (item.기록 === '실수') ? '실수' : '';
    const concepLake = (item.기록 === '개념') ? '개념' : '';
    setMistakeValue(mistake);
    setConceptLackValue(concepLake);
    setIsMarkinged(item.OX);//OX값이 있냐 공백이냐
    setIsShowOX(false);//초기화
    setMarkingNum(item.마킹);

  }


//--------------------------------------------------------------------------/중요/실수/개념
 function fncCheckImportant(kind, value) {//한 개로 통합(import, mistake, conceptLake)
    const currentDate = new Date();
    const studyDay = currentDate.toISOString().split('T')[0];// 날짜를 YYYY-MM-DD 형식으로 변환합니다.
    const munCode= list[selectedRow-1].문항번호;       
    // const kind = 'important';
    fncUpdateImportant(stCode, studyDay, munCode, value, kind )
}
 
  function fncUpdateImportant(stCode, studyDay, munCode, markingValue, kind ) {
    sendAxios("/updateImportant", {stCode, studyDay, munCode, markingValue, kind  }, (response) => {
    if (response) {
      const result = response.result;
      if (result === 'Y') {
        // alert('완료되었습니다.');
        if(kind ==='import') {
          list[selectedRow-1].중요 = markingValue;
          setImportValue(markingValue);
        }else if (kind ==='mistake') {
          list[selectedRow-1].기록 = markingValue;
          setMistakeValue(markingValue);
          setConceptLackValue('');
        }else if (kind === 'concept') {
          list[selectedRow-1].기록 = markingValue;
          setConceptLackValue(markingValue);
          setMistakeValue('');
        }
        
      }    
    } else {
      console.log("속성이 obj 내에 없습니다.");
    }
  });
}

//채점--------------------------------------------------------------------------/
function fncChejum() {

  if (imgViewState !== '문제') {
    alert('문제에서만 채점이 가능합니다.');
    return;
  }

  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];// 날짜를 YYYY-MM-DD 형식으로 변환합니다.
  const studyDay = today;
  const munCode= list[selectedRow-1].문항번호;
  const testCode= list[selectedRow-1].시험지코드;
  const no = list[selectedRow-1].no;
  const dap = list[selectedRow-1].정답;
  const cntO = list[selectedRow-1].cntO;
  const cntX = list[selectedRow-1].cntX;
  const cnt = list[selectedRow-1].cnt;

  console.log(markingList, '-----------------------markingList');
  let markingValue = markingList[selectedRow];//배열이 아니어서 selectedRow-1 하면 안됨      
  const OX = (dap === markingValue) ? 'O': 'X';

  if (!markingValue)  {
    alert('마킹을 해주세요!');//다음문제로 체크하면 markingValue=null로 되는 듯
    return;
  }

  const 정답율 = fnc정답율(OX, cntO, cntX, cnt)
  list[selectedRow-1].마킹 = markingValue;
  list[selectedRow-1].OX = OX;
  list[selectedRow-1].경과일 = '오늘';
  list[selectedRow-1].정답율 = 정답율 + '%'
  fncInsert_te_markingOne(studyDay, munCode, no, dap, OX, markingValue, testCode);

}


  function fnc정답율(OX, cntO, cntX, total) {
    const updatedCntO = OX === 'O' ? cntO + 1 : cntO;
    const updatedCntX = OX === 'X' ? cntX + 1 : cntX;
    const updatedTotal = total + 1;
    const 정답율 = Math.round((updatedCntO / updatedTotal) * 100 * 100) / 100;

    // list[selectedRow-1].cntO = updatedCntO; //하루에 한 번만 집계돼서
    // list[selectedRow-1].cntX = updatedCntX;
    // list[selectedRow-1].cnt = updatedTotal;

    return 정답율;
  }


  function fnc다음문제_자동(no) {//사용 안 함   
      // let chkNextMoonje = Cookies.get("chkNextMoonje");
      // fncSelectRow(list[no - 1]); //-1 해줘야 (고생)
      // if (chkNextMoonje == 1) {
      //   fncNext();
      // }
  }
  
  function fncInsert_te_markingOne(studyDay, munCode, no, dap, OX, markingValue, testCode) {
      sendAxios("/insertMarkingOne", {stCode, studyDay, munCode, no, OX, markingValue, testCode  }, (response) => {
      if (response) {

        let result = response.result;
        if (result === 'Y') {
          setIsMarkinged(OX);
          setInfo({OX: OX, elapsedDays: '지금',dap: dap, markingValue: markingValue});    
          // setInfo({ OX: OX, incorrectRate: 정답율, elapsedDays: 경과일, dap: dap, markingValue: markingValue });
          setOX(OX);// onFncNoBgColorClassName(OX); 랜더링 돼서 이걸로 안돼
          setChejumCompleted(true);

          // fnc다음문제_자동(no); //한 문제씩 풀기에서는 자동이동 죽임(의미없음)

        } else {
          alert('실패했습니다.');
        }   
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }

    
  const sendAxios = async (url, data, callback) => {//이건 하나만 있으면 됨

    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
        let jsonObj = response.data;
        callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };


  const fncResetMarkingList = () => {
    let rtn = window.confirm('마킹을 초기화하시겠습니까?');
    if (!rtn) return false;
    const newState = {};
    for (let i = 1; i <= munCnt; i++) {
      newState[i] = '';
    }
    setMarkingList(newState);
  };

  const fncSaveMarkingList = () => {
    localStorage.setItem(testCode, JSON.stringify(markingList));//localStorage에 저장
  }

 const fncResoreMarkingList = () => {//마킹 복원하기
  let rtn = window.confirm('이전 마킹을 가져오시겠습니까?');
  if (!rtn) return false;

   const storedMarkingList = JSON.parse(localStorage.getItem(testCode));
   setMarkingList(storedMarkingList);
   if (storedMarkingList) {
     setMarkingList(storedMarkingList);
     alert("완료되었습니다.");
     fncShowList();
   } else {
     alert("저장된 데이터가 없습니다.");
   }
 }
  
 function fncHandleMarking(no, value) {
    setMarkingList((prevMarking) => {
    const updatedMarking = { ...prevMarking, [no]: value };
    localStorage.setItem(testCode, JSON.stringify(updatedMarking)); // fncSaveMarkingList(); 비동기라 한 탠포 늦게된다.
    return updatedMarking;
  });
  
  fncCloseList();

  // let chkNextMoonje = Cookies.get("chkNextMoonje");
  // fncSelectRow(list[no - 1]); //-1 해줘야 (고생)
  // if (chkNextMoonje == 1) {
  //   fncNext();
  // }

}

const toggleSpecial = () => {
  setIsShowSpecial(prevState => !prevState);
};


useImperativeHandle(ref, () => ({
  fncNumberMarking: fncHandleMarking,
  fncInputMarking: fncHandleMarking,
  fncCheckImportant: fncCheckImportant,//markingMenuRef.current.fncCheckImportant(kind, value);
  onfncChejum: fncChejum,//markingMenuRef.current.onfncChejum
}));

  return (
    <div className="MarkingMenuOne">
       
      <div className="wrap-footer">
          <div className="box-footer">
            <input type="button" className="btn btnList" value="" onClick={fncShowList} />
            <button className="btn btnPrev" onClick={fncPrev}></button>
            <button className="btn btnNext" onClick={fncNext}></button>
            <button className="btn btnZoomOut2" onClick={fncZoomOut}></button>
            <button className="btn btnZoomIn2" onClick={fncZoomIn} ></button>
            <button className="btn btnOut" onClick={fncTestClose} ></button>
          </div>

          <div className="box-footer">
            {/* <button className="btn btn_number" onClick={() => { fncHandleMarking(selectedRow, '1'); setMarkingNum('1');}}>1</button>
            <button className="btn btn_number" onClick={() => { fncHandleMarking(selectedRow, '2'); setMarkingNum('2');}}>2</button>
            <button className="btn btn_number" onClick={() => { fncHandleMarking(selectedRow, '3'); setMarkingNum('3');}}>3</button>
            <button className="btn btn_number" onClick={() => { fncHandleMarking(selectedRow, '4'); setMarkingNum('4');}}>4</button>
            <button className="btn btn_number" onClick={() => { fncHandleMarking(selectedRow, '5'); setMarkingNum('5');}}>5</button>
            <button className="btn btn_number" onClick={() => { fncHandleMarking(selectedRow, '?'); setMarkingNum('?');}}>?</button>  */}
            
            {['1', '2', '3', '4', '5', '?'].map((num) => (
            <button
              key={num}
              className={`btn btn_number ${markingNum === num ? 'btnSelected' : ''}`}
              onClick={() => {fncHandleMarking(selectedRow, num); setMarkingNum(num); }} > {num} </button>
            ))}
            
            
            <button className="btn btn_submit" onClick={() => {fncChejum();}}>채점</button>
          </div>

          <div className="box-special-footer">
            <input type="text" className="txtmarking" value={inputValue} onChange={fncInputValueChange} autoComplete="off" />
            <button className="btn_special_footer" onClick={() => {setInputValue('');setMarkingNum('');}}>cls</button>
      
            <button className="btn_special_footer" onClick={fncInsertMarkingValue}>넣기</button>
            <button className="btn_special_footer" onClick={toggleSpecial}>특수</button>
            <button className="btn_special_footer" onClick={() => {fncResetMarkingList();}}>초기화</button>
            <button className="btn_special_footer" onClick={() => {fncResoreMarkingList();}}>복원</button>
            
          </div>
      </div>

      
      
      { isShowSpecial && (<MarkingMenuSpecial 
          setInputValue={setInputValue} 
          isShowSpecial={isShowSpecial}
          setIsShowSpecial={setIsShowSpecial}
        />
      )}


    </div>
  );
});

export default MarkingMenuOne;
