import React from "react";
import { useNavigate } from "react-router-dom"; // Change this
// import styles from './Main.css'; // 또는 사용하는 CSS 파일의 경로
import './Menu.scss';
import Header from "./Header";
import svg_book from "./images/page/book.svg"; 
import svg_report from "./images/page/report.png"; 

import svg_ai from "./images/page/ai.svg"; 
import svg_exam from "./images/page/exam.png"; 

import svg_notice from "./images/page/notice.png"; 
import svg_myroom from "./images/page/myRoom.svg"; 
import svg_admin from "./images/page/admin.svg"; 
import svg_finger from "./images/page/hand.png";
import svg_pen from "./images/page/pencil.png";

const Menu = () => {
let navigate = useNavigate();  // Change this


  return (
    <div className="Menu"> 

     
      <div className={'container_menu'}>
      <Header onBackClick={() => navigate("/")} title="학습메뉴" />
          <div className={`box_menu_main mt-3`}>

            <div className={`boxBtn finger phone-disable`} onClick={() => navigate("/markinglist")}>
              <img className={'img'} src={svg_exam} alt="" />
              <div className={'img_text'}>TEST</div>
            </div>     

            <div className={`boxBtn finger phone-disable`} >
             
              <div className={'img_text'}>모든 기능을 사용하시려면 <br/> Pad로 접속하세요!</div>
            </div>  


            {/* <div className={`boxBtn finger`} onClick={() => navigate("/markinglistone")}>
              <img className={'img'} src={svg_finger} alt="" />
              <div className={'img_text'}>한 문제씩 풀기</div>
            </div> */}



            <div className={`boxBtn pencil tablet-disable`} onClick={() => navigate("/markinglist2")}>
              <img className={'img'} src={svg_exam} alt="" />
              <div className={`img_text`}>TEST <p>전체문제 풀고 제출</p></div>
   
            </div>

            <div className={`boxBtn pencil tablet-disable`} onClick={() => navigate("/markinglistone2")}>
              <img className={'img'} src={svg_ai} alt="" />
              <div className={'img_text'}>STDUY <p>한 문제씩 풀고 채점</p></div>
            </div>


            {/* <div className={s.boxBtn} onClick={() => navigate("/odapnoteapp")}>
            <img className={s.img} src={svg_exam} alt="" />
              <div className={s.img_text}>메모 테스트</div>
            </div> */}

            {/* <div className={`${s.boxBtn} d-none`} onClick={() => navigate("/svgdraw")}>
            <img className={s.img} src={svg_exam} alt="" />
              <div className={s.img_text}>메모(pad)</div>
            </div> */}

            <div className={`boxBtn d-none tablet-disable`} onClick={() => navigate("/dexie")}>
              <img className={'img'} src={svg_admin} alt="" />
              <div className={'s.img_text'}>IndexedDB TEST(Dexie)</div>
            </div>


            <div className={`boxBtn d-none`} onClick={() => navigate("/svgdrawmouse")}>
            <img className={'img'} src={svg_book} alt="" />
              <div className={'s.img_text'}>메모(pc)</div>
            </div>

           
{/* 
            <div className={`${s.boxBtn} d-none`} onClick={() => navigate("/")}>
              <img className={s.img} src={svg_exam} alt="" />
              <div className={s.img_text}>메뉴 1</div>
            </div>

            <div className={`${s.boxBtn} d-none`} onClick={() => navigate("/")}>
              <img className={s.img} src={svg_exam} alt="" />
              <div className={s.img_text}>메뉴 2</div>
            </div>

            <div className={s.boxBtn} onClick={() => navigate("/")}>
              <img className={s.img} src={svg_ai} alt="" />
              <div className={s.img_text}>정오답노트</div>
            </div>

            <div className={s.boxBtn} onClick={() => navigate("/")}>
              <img className={s.img} src={svg_report} alt="" />
              <div className={s.img_text}>시험결과</div>
            </div>

            <div className={s.boxBtn} onClick={() => navigate("/")}>
              <img className={s.img} src={svg_notice} alt="" />
              <div className={s.img_text}>공지사항</div>
            </div>

            <div className={s.boxBtn} onClick={() => navigate("/")}>
              <img className={s.img} src={svg_myroom} alt="" />
              <div className={s.img_text}>마이페이지</div>
            </div>

            <div className={s.boxBtn} onClick={() => navigate("/")}>
              <img className={s.img} src={svg_admin} alt="" />
              <div className={s.img_text}>관리자 페이지</div>
            </div> */}

          

            <br></br>
          </div>

      </div>
    </div>
  );
};

export default Menu;
