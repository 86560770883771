
import './Login.scss';
// import Button from 'react-bootstrap/Button';
import { Button } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
// import bookImage from '../images/logo.gif';
import fnc from '../mymod/commFunction';
import bookImage from '../images/page/ai.svg';
import { useSelector, useDispatch } from 'react-redux';

const svrUrl = process.env.REACT_APP_SERVER_URL;

let acaDbName;

function Login() {
    const [certCodeSt, setCertCodeSt] = useState('');
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [appVersion, setAppVersion] = useState(''); // 앱 버전 상태
    const [isAutoLoginChecked, setAutoLoginChecked] = useState(false);
    const [isIdChecked, setIdChecked] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const navigate = useNavigate();  // 여기서 useNavigate hook을 사용합니다.
    const version = useSelector(state => state.reduxStore.version);

    useEffect(() => {
      restoreCookie();//왜 2번 했었지?
    //window.addEventListener("sendData", handleDataEvent); 이방식 포기(안 됨)
      setAppVersion(version);//초기값 공백으로 해야하고, [version] 붙여야함 안드로이드에서 못 읽어와
    }, [version]);

  
    function loginClick() {
      if (!certCodeSt) return alert('학원코드를 입력하세요!');
      if (!id) return alert('아이디를 입력하세요!');
      if (!pw) return alert('패쓰워드를 입력하세요!');

      fncInfoAca();
    }

    function fncInfoAca() {

      sendAxios('/infoAca', { certCodeSt }, (response) => {

        if (response && Array.isArray(response) && response[0] && response[0].acaDbName) {
         
          acaDbName = response[0].acaDbName;
          let acaCode = response[0].acaCode;
          let imageWebPath = response[0].imageWebPath;
    
          fnc.setCookie('acaDbName', acaDbName, 1000);
          fnc.setCookie('acaCode', acaCode, 1000);
          fnc.setCookie('imageWebPath', imageWebPath, 1000);
    
    
          fncLogin();//로그인 실행

        } else {
          alert('학원코드 정보가 올바르지 않습니다.');
        }
      });
    }


    function fncLogin() {
      sendAxios('/login', { id, pw }, (response) => {
        //Array.isArray(response) 이거 추가하지 않으면 인식 못한다. [] 이 결과에 대해서
        if (response && Array.isArray(response) && response[0] && response[0].stCode) {
          let stCode = response[0].stCode;
          saveCookie(id, pw, stCode);
          navigate("/main")
        } else {
          alert('아이디 또는 패쓰워드 정보가 올바르지 않습니다.');
        }
      });
    }
    
    
    const sendAxios = async (url, data, callback) => {
      try {
        const response = await axios.post(`${svrUrl}${url}`, data);
        let jsonObj = response.data;
        callback(jsonObj);
      } catch (error) {
        // callback('err');
        console.error("Axios 요청 중 오류가 발생했습니다2:", error);
      }
  };

    
  axios.interceptors.request.use(config => {
    //login보다 먼저 실행돼야 한다.

    let acaDbName = fnc.getCookie('acaDbName');
    // 모든 POST 요청에 데이터 추가
    if (config.method === 'post' && config.data) {
      config.data.db  = acaDbName;// 'Autogen0000';//acaDbName;
      config.data.name3 = '홍길동33';
    }
  
    // 모든 GET 요청에 쿼리 파라미터 추가
    if (config.method === 'get') {
      config.params = config.params || {};
      config.params.name3 = '홍길동2';
    }
  
    return config;
  }, error => {
    // 요청 오류 처리
    console.error('Axios 요청 인터셉터에서 오류 발생:', error);
    return Promise.reject(error);
  });
  
  


    const handleIdClick = () => {
      if (isIdChecked) {
          setAutoLoginChecked(false);
          fnc.setCookie('isAutoLoginChecked', 'false', 1000);
      }
      setIdChecked(prev => !prev);
      fnc.setCookie('isIdChecked', String(!isIdChecked), 1000);
  }
  
  const handleAutoLoginClick = () => {
    if (!isAutoLoginChecked) {
        setIdChecked(true);
        fnc.setCookie('isIdChecked', 'true', 1000);
    }
    setAutoLoginChecked(prev => !prev);
    fnc.setCookie('isAutoLoginChecked', String(!isAutoLoginChecked), 1000);
}

     
  const saveCookie = (id, pw, stCode) => {
    fnc.setCookie('certCodeSt', certCodeSt, 1000);
    fnc.setCookie('id', id, 1000);
    fnc.setCookie('pw', pw, 1000);
    fnc.setCookie('stCode', stCode, 1);
}


  function restoreCookie() {
    const certCodeSt= fnc.getCookie('certCodeSt');
    const id=fnc.getCookie('id');
    const pw=fnc.getCookie('pw');
    const isIdChecked = fnc.getCookie('isIdChecked') === 'true';
    const isAutoLoginChecked = fnc.getCookie('isAutoLoginChecked') === 'true';
    
    setIdChecked(isIdChecked);
    setAutoLoginChecked(isAutoLoginChecked);

    if (certCodeSt) {
      setCertCodeSt(certCodeSt);
    }

    if (id && isIdChecked) {
      setId(id);
    }

    if (pw && isAutoLoginChecked) {
      setPw(pw);
    }
  }

  
  
  function onUpdate() {
    if (typeof window.myJSInterface !== 'undefined') {
      // // 첫 번째 통신
      // if (window.myJSInterface.callWebSite) {
      //   window.myJSInterface.callWebSite('https://naver.com');
      // } else {
      //   console.error("callWebSite feature is not available on your platform.");
      // }
  
    //   // 두 번째 통신
    //   if (window.myJSInterface.callbackHandlerOpenForm) {
    //     window.myJSInterface.callbackHandlerOpenForm('https://naver.com');
    //   } else {
    //     console.error("callbackHandlerOpenForm feature is not available on your platform.");
    //   }
    // } else {
    //   console.error("Android Interface is not available on this platform.");
    // }

    //세 번째 통신
    if (window.myJSInterface.callbackHandlerOpenForm) {
      window.myJSInterface.closeApp('');
    }
    

   }
}
  
      //==================================================================================
      return (
        <div className='Login'>
       
          <div className='box-title'>
            <h3 className='sub_title'>SUPER-BOT</h3>
          </div>

         
            
          <div className="login-container fs-5">
              <input type="text" className='chkbox' value={certCodeSt} onChange={(e) => setCertCodeSt(e.target.value)} placeholder="인증코드"  required  
                  onFocus={() => setIsInputFocused(true)}  onBlur={() => setIsInputFocused(false)} /> 
              <input type="text" className='chkbox'  value={id} onChange={(e) => setId(e.target.value)} placeholder="아이디" required
                  onFocus={() => setIsInputFocused(true)}  onBlur={() => setIsInputFocused(false)}/> 
              <input type="password" className='chkbox' value={pw} onChange={(e) => setPw(e.target.value)} placeholder="패쓰워드" required 
                  onFocus={() => setIsInputFocused(true)}  onBlur={() => setIsInputFocused(false)}/>
              <Button className="btn_login" onClick={() => loginClick(id, pw)}>로그인</Button>
              {false && <Button className="btn_update" onClick={onUpdate}>업데이트</Button>}
          
            <div className="box-chkbox">
                <label className="form-check-label lblChk">
                    <input className="form-check-input" type="checkbox" 
                    checked={isIdChecked} onChange={handleIdClick} /> <span>아이디저장</span> </label>
                <label className="form-check-label lblChk">
                    <input className="form-check-input" type="checkbox"
                    checked={isAutoLoginChecked}  onChange={handleAutoLoginClick} /> <span>자동로그인</span> </label>
            </div>

           { isInputFocused || true && <div className={`wrap_logo hide-on-logo ${isInputFocused ? 'hide' : ''}`}>
                      <div className='box_logo'>
                        <img className="img_logo" src={bookImage} alt="logo" />   {/* 상대경로 못 읽어 */}
                      </div>
            </div> }
    
        </div>

          {appVersion && <div className='ver-info'>{appVersion}</div>} 
          {!appVersion && <div className='ver-info'>Aplication ver 1.0.1</div>}

        </div>
      );
}


export default Login;
