import styles from "./markingOneApp.css"; //css는 Top에 와야..
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import MarkingQue from "./MarkingQueOne";
import MarkingTitleBar from "./MarkingTitleBarOne";
import MarkingSetting from "../marking/MarkingSetting";
import MarkingTable from "./MarkingTableOne";
import MarkingMenu from "./MarkingMenuOne";

import ShowDap from "./ShowDap";
import Cookies from 'js-cookie';

const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingOneApp() {
  const navigate = useNavigate(); 
  const location = useLocation();
  const testNm = location.state?.testNm;
  const munCnt= location.state?.munCnt;

  const isFirstRender = useRef(true);
  const markingMenuRef = useRef();//MarkingMenu의 버튼 클릭 함수 참조
  const MarkingTitleBarRef = useRef();

  const [list, setList] = useState(null);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isShowList, setIsShowList] = useState(true);
  const [no, setNo] = useState("1번");
  const [info, setInfo] = useState({OX: null, incorrectRate: 0, elapsedDays: 0, dap: null });
   
  const [imageSize, setImageSize] = useState(300);
  const [showMarkingSetting, setShowMarkingSetting] = useState(false)
  const [chkNextMoonje, setChkNextMoonje] = useState(() => Cookies.get('chkNextMoonje'));
  const [imgSize, setImgSize] = useState(300);

  const [markingList, setMarkingList] = useState(() => {
    const initialState = {};
    for (let i = 1; i <= munCnt; i++) {
      initialState[i] = '';
    }
    return initialState;
  });

  const [showModal, setShowModal] = useState(false);
  const [imgAppendStart, setImgAppendStart] = useState(false);
  const [slideHandlePrevNext, setSlideHandlePrevNext] = useState(null);
  const [importValue, setImportValue] = useState(null);
  const [mistakeValue, setMistakeValue] = useState(null);
  const [conceptLackValue, setConceptLackValue] = useState(null);
  const [isMarkinged, setIsMarkinged] = useState(null);
  const [imgViewState, setImgViewState] = useState("문제"); // 기본 상태로 '문제' 설정
  const [isShowOX, setIsShowOX] = useState(false);
  const [OX, setOX] = useState();
  const [markingNum, setMarkingNum] = useState();
  const [chejumCompleted, setChejumCompleted] = useState(false);
  const [isActiveQue, setIsActiveQue] = useState(false);
  const [kind, setKind] = useState('que');

  console.log("0=======================================================>>MarkingApp" );

  //-----------------------------------------------------------------
  useEffect(() => {//로딩 후 첫번째 문제 띄우기 (parent에서 해야 함)
  if (list && isFirstRender.current) {
    fncHandleNext();
    isFirstRender.current = true;
  }
  }, [list]);



  function fncHandleNext() {
    if (list && selectedRow !== null) {
      const nextIndex = selectedRow; 
      if (nextIndex < list.length) {
        const nextItem = list[nextIndex];
        setSelectedRow(nextItem.no);
        fncSelectRow(nextItem)
        
      } else {
        alert('마지막입니다!');
      }
    }
  }

  function fncPrev() {//memoCanvas 슬라이드 여기와 연동
    if (list && selectedRow !== null) {
      const currentIndex = selectedRow - 1;  // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
      if (currentIndex > 0) {
        const nextItem = list[currentIndex - 1];
        fncSelectRow(nextItem);
        setSlideHandlePrevNext(1);
        setKind('que');//메모 슬라이드 시 문제로 초기화
      } else {
        alert("처음입니다!");
      }
    }
  }

  function fncNext() {//이건 titleBar의 > 인데 invisible해 놨다. (공간이 없어서) 슬라이드할 때도 영향을 받네?
    if (list && selectedRow !== null) {
      const nextIndex = selectedRow; // item.no가 인덱스 + 1 이므로
      if (nextIndex < list.length) {
        const nextItem = list[nextIndex];
        fncSelectRow(nextItem);
        setSlideHandlePrevNext(-1);
        setKind('que');//메모 슬라이드 시 문제로 초기화
      } else {
        alert('마지막입니다!!');
      }
    }
  }



  function fncSelectRow(item) {//3군데 바꿔야 함(Table, Menue, App)
    if (!item) return false;
    setSelectedRow(item.no);
    setNo(item.no + '번');

    //-----------------------------------
    setImportValue(item.중요);

    const OX = item.OX;
    const 정답율 = item.정답율;
    const 경과일 = item.경과일;
    const dap = item.정답;
    const markingValue = item.마킹;
    
    setInfo({ OX: OX, incorrectRate: 정답율, elapsedDays: 경과일, dap: dap, markingValue: markingValue });

    const mistake = (item.기록 === '실수') ? '실수' : '';
    const concepLake = (item.기록 === '개념') ? '개념' : '';
    setMistakeValue(mistake);
    setConceptLackValue(concepLake);
    setIsMarkinged(item.OX);//OX값이 있냐 공백이냐
    setIsShowOX(false);//초기화
    setMarkingNum(item.마킹);
    
  }

const handleCloseSetting = () => {
  setShowMarkingSetting(false); 
}


function fncTestClose() {
  const confirmed = window.confirm('시험을 종료하시겠습니까?');
  if (confirmed) {
    navigate("/markinglistone");
  }
}


const callMenuFncFromTitleBar_fncNumberMarking = (no, value) => {
  markingMenuRef.current.fncNumberMarking(no, value);
}

const callMenuFncFromTitleBar_fncInputMarking = (no, value) => {
  markingMenuRef.current.fncInputMarking(no, value);
}

const callMenuFncFromTitleBar_fncUpdateImport = (kind, value) => {
  //kind = 'import, mistake, conceptLake
  markingMenuRef.current.fncCheckImportant(kind, value);
}

const CallMarkingTitlebarFunctionFromMenu_fncNoBgColorClassName = (OX) => {
  MarkingTitleBarRef.current.fncNoBgColorClassName(OX);
}

const callMenuFncFromTitleBar_fncChejum = () => {
  markingMenuRef.current.onfncChejum();
}

  return (
    <div className="MarkingOneApp">
      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={testNm} />
      </div>

     { /* 한 문제씩에서는 사용 안 함 의미 없음
      { false && <input type="button" className="btnSetting" onClick={() => setShowMarkingSetting(true)} /> }

      {showMarkingSetting && (
        <MarkingSetting
          setChkNextMoonje={setChkNextMoonje}
          handleClose={handleCloseSetting}
        />
      )} */}


      {!isShowList && list && selectedRow && ( <MarkingTitleBar 
          ref={MarkingTitleBarRef}
          no={no} 
          info={info} 
          selectedList={list[selectedRow-1]}
          testNm={testNm}
          showModal={showModal} setShowModal={setShowModal}
          fncPrev={fncPrev}
          fncNext={fncNext}
          slideHandlePrevNext={slideHandlePrevNext} setSlideHandlePrevNext={setSlideHandlePrevNext}
          setIsShowList={setIsShowList}   
          selectedRow={selectedRow}
          onMarkingNumber={callMenuFncFromTitleBar_fncNumberMarking}
          onMarkingInput={callMenuFncFromTitleBar_fncInputMarking}
          onMarkingImport={callMenuFncFromTitleBar_fncUpdateImport}
          onFncChejum={callMenuFncFromTitleBar_fncChejum}
          isStudy={false}
          importValue={importValue}
          mistakeValue={mistakeValue}
          conceptLackValue={conceptLackValue}
          isMarkinged={isMarkinged}
          setImgViewState={setImgViewState}
          isShowOX={isShowOX} setIsShowOX={setIsShowOX}
          OX={OX} setOX={setOX}
          markingNum={markingNum}
          setMarkingNum={setMarkingNum} 
          isActiveQue={isActiveQue} setIsActiveQue={setIsActiveQue}
          kind={kind} setKind={setKind}
          />
      )}

      {
        <MarkingTable
          isShowList={isShowList}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          list={list}
          setList={setList}
          setNo={setNo}
          setInfo={setInfo}
          markingList={markingList}
          setImgSize={setImageSize}
          setImportValue={setImportValue}
          setMistakeValue={setMistakeValue}
          setConceptLackValue={setConceptLackValue}
          setIsMarkinged={setIsMarkinged}
          setIsShowOX={setIsShowOX}
          setMarkingNum={setMarkingNum}

        />
      }

  
        <MarkingQue  
          list={list}
          selectedRow={selectedRow}
          imgSize={imgSize}
          setIsShowList={setIsShowList}
          fncPrev={fncPrev}
          fncNext={fncNext}
          imgViewState={imgViewState} setImgViewState={setImgViewState}
          isShowOX={isShowOX}
          chejumCompleted={chejumCompleted} setChejumCompleted={setChejumCompleted}
          OX={OX}
        />

        {/* {isShowOX && <ShowOX 
         selectedList={list[selectedRow-1]}
        />} */}
      
      { isShowOX && <ShowDap 
                info={info}
                setIsShowOX={setIsShowOX} />}

        <MarkingMenu     
          ref={markingMenuRef} 
          selectedRow={selectedRow} setSelectedRow={setSelectedRow}
          setNo={setNo}
          setInfo={setInfo}
          isShowList={isShowList} setIsShowList={setIsShowList}
          list={list}
          imgSize={imgSize}
          setImgSize={setImgSize}
          markingList={markingList}
          setMarkingList={setMarkingList}
          showModal={showModal} setShowModal={setShowModal}
          setImportValue={setImportValue}
          setMistakeValue={setMistakeValue}
          setConceptLackValue={setConceptLackValue}
          setIsMarkinged={setIsMarkinged}
          imgViewState={imgViewState} setImgViewState={setImgViewState}
          setIsShowOX={setIsShowOX}
          onFncNoBgColorClassName={CallMarkingTitlebarFunctionFromMenu_fncNoBgColorClassName}
          OX={OX} setOX={setOX}
          markingNum={markingNum} setMarkingNum={setMarkingNum}
          chejumCompleted={chejumCompleted} setChejumCompleted={setChejumCompleted}
          isActiveQue={isActiveQue} setIsActiveQue={setIsActiveQue}

        />
   
       

    </div>


  );
}

export default MarkingOneApp;
